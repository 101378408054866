// import VueCookies from 'vue-cookies'
import vuetify from '@/plugins/vuetify'
import { apolloClientV2 } from '@/setup/apollo.js'
const state = {
  darkMode: false,
  themeColor: null,
  themeColorDark: null,
  themeColorAccent: null,
  notificationsSidebar: false,
  tutorials: {
    home: false,
    devoirEdition: false,
    tableauEdition: false
  }
  // activeActionsRapides: [1, 2, 4]
}

const getters = {}

const actions = {
  async setPreferences ({ commit, state }, value) {
    // il faut faire l'objet
    const pref = {
      darkMode: state.darkMode,
      themeColor: state.themeColor,
      themeColorAccent: state.themeColorAccent,
      themeColorDark: state.themeColorDark,
      tutorials: state.tutorials
      // activeActionsRapides: state.activeActionsRapides
      // Pour reset les actions aux cas ou
      // activeActionsRapides: [2, 4]
    }
    pref[value.name] = value.data
    await apolloClientV2.mutate({
      mutation: require('@/graphql/mutations/v2/updateSalleClasseUsager.gql'),
      variables: {
        info: { meta: JSON.stringify(pref) }
      }
    }).then(({ data }) => {
      if (!data.updateSalleClasseUsager || data.updateSalleClasseUsager.errors) {
        console.error(data ? data.updateSalleClasseUsager.errors : 'erreur lors de la mutation')
      } else {
        // ON VA METTRE LA PREMIERE LETTRE EN MAJUSCULE POUR FITTER AVEC LES NOMS DE MUTATIONS
        // charAt(0).toUpperCase() + this.slice(1)
        const name = value.name[0].toUpperCase() + value.name.slice(1)
        // console.log('name', 'set' + name, value.data)
        commit('set' + name, value.data)
      }
    })
  },
  async fetchPreferences ({ commit }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseUsagerPreferences.gql'),
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        if (data && data.salleClasseUsager && data.salleClasseUsager.info && data.salleClasseUsager.info.meta) {
          const pref = JSON.parse(data.salleClasseUsager.info.meta)
          // console.log('preferences', pref)
          // ON FAIT LES DIFFERENTS COMMITS
          commit('setDarkMode', pref.darkMode)
          commit('setThemeColor', pref.themeColor)
          commit('setThemeColorAccent', pref.themeColorAccent)
          commit('setThemeColorDark', pref.themeColorDark)
          commit('setTutorials', pref.tutorials)
          // commit('setActiveActionsRapides', pref.activeActionsRapides)
          // Pour reset les actions aux cas ou
          // commit('setTutorials', { home: false, devoirEdition: false, tableauEdition: false })
          // commit('setActiveActionsRapides', [2, 4])
        }
      })
    } catch (e) {
      console.error(e)
    }
  },
  resetPreferences ({ commit }) {
    // console.log('on reset les preferences')
    commit('setDarkMode', false)
    commit('setThemeColor', '#ff284f')
    commit('setThemeColorAccent', '#ffe2ce')
    commit('setThemeColorDark', '#ff284f')
    commit('setTutorials', { home: false, devoirEdition: false, tableauEdition: false })
    // commit('setActiveActionsRapides', [1, 2, 4])
  }
}

const mutations = {
  setDarkMode (state, payload) {
    state.darkMode = payload
    vuetify.framework.theme.dark = payload
    vuetify.framework.theme.themes.dark.accent = '#4a313b'
  },
  setThemeColor (state, payload) {
    if (payload) {
      state.themeColor = payload
      vuetify.framework.theme.themes.light.primary = payload
    }
  },
  setThemeColorAccent (state, payload) {
    if (payload) {
      state.themeColorAccent = payload
      vuetify.framework.theme.themes.light.accent = payload
    }
  },
  setThemeColorDark (state, payload) {
    if (payload) {
      state.themeColorDark = payload
      vuetify.framework.theme.themes.dark.primary = payload
    }
  },
  setTutorials (state, payload) {
    // state.tutorials = null
    state.tutorials = payload
  }
  // setActiveActionsRapides (state, payload, isArray) {
  //   state.activeActionsRapides = payload
  //   // console.log('nouvewlles actions rapides', state.activeActionsRapides)
  // },
  // setDyslexicFont (state, payload) {
  //   state.dyslexicFont = payload
  //   // VueCookies.set('dyslexic_enseignant', state.dyslexicFont)
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
