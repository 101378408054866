<script>
export default {
  name: 'devoirTutorial',
  components: {
  },
  data () {
    return {
      hideTutorials: this.$store.state.Preferences.tutorials.devoirEdition,
      hideAllTutorials: false
    }
  },
  watch: {
    hideTutorials (newVal) {
      this.finishTuto(this.hideTutorials)
    },
    hideAllTutorials () {
      this.toggleAllTuto(this.hideAllTutorials)
    }
  },
  computed: {
    showTutorialDialog: {
      set (val) { this.$store.commit('Dialogs/setShowTutorialDialog', val) },
      get () { return this.$store.state.Dialogs.showTutorialDialog }
    }
  },
  methods: {
    launchTour () {
      this.$emit('startTour')
      this.showTutorialDialog = false
      this.finishTuto()
    },
    finishTuto () {
      this.$emit('updateTuto', 'devoirEdition')
    },
    toggleAllTuto (val) {
      this.$emit('toggleAllTuto', val)
    }
  }
}
</script>
<template lang='pug'>
  div
    v-sheet.step-introduction(:color='$store.state.Preferences.darkMode ? "light" : "white"', light)
      h1.step-title Bienvenue dans le créateur de devoir.
      div.step-description
        p Cet espace vous permettra d'avoir un contrôle total sur votre facon d'enseigner. Nous vous proposons une visite guidée pour vous familiariser avec vos nouveaux outils.
        p.i.o-50() Vous pourrez ajuster ces informations plus tard, au besoin.
    div.step-content(style='min-height: unset')
      v-list.intro-list()
        v-list-item(@click='launchTour()')
          v-list-item-avatar(color='accent', size='50')
            font-awesome-icon.fa-2x(:icon="['fad', 'binoculars']")
          v-list-item-content
            v-list-item-title Lancer la visite guidée
      div.flex.items-center.justify-start.flex-column
        v-checkbox(v-model='hideTutorials', label='Masquer ce tutoriel', color='primary', hide-details)
        v-checkbox(v-model='hideAllTutorials', label='Masquer tous les tutoriels', color='primary', hide-details)

</template>
<style lang='sass'>

</style>
