import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { apolloClientV2 } from '../setup/apollo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import('../views/Profil.vue')
  },
  {
    path: '/calendrier',
    name: 'calendrier',
    component: () => import('../views/Calendar.vue')
  },
  {
    path: '/historique',
    name: 'historique',
    component: () => import('../views/Historique.vue')
  },
  {
    path: '/ressources',
    name: 'ressources',
    component: () => import('../views/Ressources.vue')
  },
  {
    path: '/boutique',
    name: 'boutique',
    component: () => import('../views/boutique/index.vue')
  },
  {
    path: '/bibliotheque',
    name: 'bibliotheque',
    component: () => import('../views/Bibliotheque.vue')
  },
  {
    path: '/tableau/:id',
    name: 'tableauDetail___',
    component: () => import('../views/tableau/_id.vue')
  },
  {
    path: '/tableau',
    name: 'tableau',
    component: () => import('../views/tableau.vue')
  },
  {
    path: '/editeur-tableau',
    name: 'editeurTableau',
    component: () => import('../views/tableau/editeur.vue')
  },
  {
    path: '/enfants',
    name: 'enfants',
    component: () => import('../views/enfants/index.vue')
  },
  {
    path: '/enfants/:id',
    name: 'enfantDetail',
    component: () => import('../views/enfants/_id.vue')
  },
  {
    path: '/enfants/:id/devoirs/:idDevoir',
    name: 'enfantsDetailDevoir',
    component: () => import('../views/enfants/devoirs/_idDevoir.vue')
  },
  {
    path: '/devoirs/:id',
    name: 'devoirDetail',
    component: () => import('../views/devoirs/_id.vue')
  },
  {
    path: '/connexion',
    name: 'connect',
    component: () => import('../views/Connect.vue')
  },
  {
    path: '/tableaux-achats',
    name: 'tableauxAchats___',
    component: () => import('../views/tableau/achats.vue')
  },
  {
    path: '/exercices',
    name: 'exercices',
    component: () => import('../views/Exercices.vue')
  },
  {
    path: '/editeur-devoir',
    name: 'devoirEdition',
    component: () => import('../views/devoirs/editeur.vue')
  },
  {
    path: '/devoirs/:id',
    name: 'devoirDetail',
    component: () => import('../views/devoirs/_id.vue')
  },
  {
    path: '/devoirs',
    name: 'devoirs___',
    component: () => import('../views/devoirs/index.vue')
  }
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  // base: '/parent',
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)
  try {
    if (to.query && to.query.dokUANE && to.query.access) {
      // console.log('on peut loger user')
      await apolloClientV2.mutate({
        mutation: require('@/graphql/mutations/v2/identificationSalleClasse.gql'),
        variables: {
          username: to.query.dokUANE,
          accessCode: to.query.access
        }
      }).then(async ({ data }) => {
        const dateExp = new Date()
        dateExp.setDate(dateExp.getDate() + 1)
        await store.dispatch('User/login', { token: data.identificationSalleClasse, date_expiration: dateExp, user: null })
        // Vue.$cookies.set('classParentUser', { username: to.query.dokUANE, access_code: to.query.access.toUpperCase() }, 60 * 60 * 24, '/', process.env.VUE_APP_DOMAIN)
        let domain = window.location.hostname
        if (domain !== 'localhost') {
          const data = domain.split('.')
          data[0] = ''
          domain = data.join('.')
        }
        Vue.$cookies.set('classParentUser', { username: to.query.dokUANE, access_code: to.query.access.toUpperCase() }, 60 * 60 * 24, '/', domain)
        // window.location.href = 'http://localhost:8080'
        window.location.href = window.location.origin
      })
    } else {
      await store.dispatch('User/fetchMinProfile')
      store.commit('App/setRouteName', to.name)
      // AuthGuard basique
      // l'utilisateur est il connecté et son profil est Parent ?
      // console.log('connected', store.state.User.connected)
      // console.log('les roles', store.state.User.roles)
      if (store.state.User.connected && store.state.User.roles.find(e => e.includes('parent'))) {
        if (to.name !== 'connect') {
          next()
        } else {
          next({ name: 'home' })
        }
      } else {
        // si on a un role mais pas le bon alors on clean user et cookie
        if (to.name !== 'connect') {
          next({ name: 'connect' })
        } else {
          next()
        }
      }
    }
  } catch (e) {
    console.error('error user', e)
  }
})
export default router
