import { apolloClientV2 } from '@/setup/apollo.js'
export async function loginUserV2 (tag, code, origin) {
  if (origin) {
    this.error = false
  }
  try {
    await apolloClientV2.mutate({
      mutation: require('@/graphql/mutations/v2/identificationSalleClasse.gql'),
      variables: {
        username: tag,
        accessCode: code
      }
    }).then(async ({ data }) => {
      if (data.identificationSalleClasse) {
        if (this.$route.query && this.$route.query.dokUANE) {
          this.$router.push({ query: null })
        }
        const dateExp = new Date()
        dateExp.setDate(dateExp.getDate() + 1)
        this.$store.dispatch('User/login', { token: data.identificationSalleClasse, date_expiration: dateExp, user: null })
        this.$store.dispatch('User/connectFrom', 'eleve')
        // this.$cookies.set('classParentUser', { username: tag, access_code: code.toUpperCase() }, 60 * 60 * 24, '/', process.env.VUE_APP_DOMAIN)
        let domain = window.location.hostname
        if (domain !== 'localhost') {
          const data = domain.split('.')
          data[0] = ''
          domain = data.join('.')
        }
        this.$cookies.set('classParentUser', { username: tag, access_code: code.toUpperCase() }, 60 * 60 * 24, '/', domain)
        if (origin) {
          this.$nextTick(() => {
            window.location.reload()
            // this.$router.push({ name: 'home' })
          })
        }
      } else {
        if (origin) {
          this.error = true
          this.errorBlob()
        }
        const error = { msg: 'Problème avec compte' }
        throw error
      }
      return data
    })
  } catch (e) {
    console.error(e)
  }
}

export default {
  methods: {
    loginUserV2,
    async logout () {
      await this.$store.dispatch('User/logOut')
      window.location.reload()
    }
  }
}
