import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

dayjs.locale('fr')
dayjs.extend(relativeTime)

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get () {
      return dayjs
    }
  }
})
