<script>
// import DistributionItem from '@/components/dialogs/actions/distributionItem'

export default {
  name: 'InformationsExercice',
  props: {
    infoSup: { required: false, type: Object, default: () => null }
  },
  data () {
    return {
      loading: true
    }
  },
  apollo: {
    niveaux: {
      query: require('@/graphql/queries/v2/communNiveaux.gql'),
      variables () {
        return {
          ids: this.infoSup.niveau_ids
        }
      },
      update (data) {
        return data.communNiveaux
      },
      skip () {
        return !this.infoSup?.niveau_ids?.length
      }
    },
    matieres: {
      query: require('@/graphql/queries/v2/communMatieres.gql'),
      variables () {
        return {
          ids: this.infoSup.matiere_ids
        }
      },
      update (data) {
        return data.communMatieres
      },
      skip () {
        return !this.infoSup?.matiere_ids?.length
      }
    },
    natures: {
      query: require('@/graphql/queries/v2/exercicesNatures.gql'),
      client: 'plus',
      variables () {
        return {
          ids: Object.keys(this.infoSup.natures).map(e => { return Number(e) })
        }
      },
      update (data) {
        this.loading = false
        return data.exercices_natures
      },
      skip () {
        return !this.infoSup.natures
      }
    }
  },
  computed: {
    componentDetail () {
      return this.natures.map(e => {
        e.count = this.infoSup.natures[e.id]
        return e
      }).sort((a, b) => b.count - a.count)
    }
  }
}
</script>

<template lang='pug'>
div.ml2
  //- Exos
  .mb-3
    span.f5.b {{ $tc('exercices.question-nbr', infoSup.nb_questions, { nb: infoSup.nb_questions } ) }}
    div.mb2.ml2(v-if='infoSup.nb_questions !== infoSup.nb_questions_moyenne')
      span(v-html='$t("exercices.exercice-moyenne")')
      span {{infoSup.nb_questions_moyenne}} {{ $tc('exercices.question-multiple', infoSup.nb_questions_moyenne) }}

  //- Comportement
  .mb-3
    span.f6.b {{$t('exercices.exercice-comportement')}}
    .chips-list(v-if="!loading")
      v-chip(v-for='(comp, index) in componentDetail' small :key='index')
        span.b.mr1 {{comp.count}}
        span(v-if='comp.count > 1') {{comp.pluriel}}
        span(v-else) {{comp.titre}}
    v-progress-circular.tc(v-else indeterminate, color="primary")
  //- Matieres
  .mb-3(v-if="matieres && matieres.length")
    span.f6.b {{$t('exercices.exercice-matieres')}}
    .chips-list
      v-chip(v-for='(matiere, index) in matieres' small :key='index') {{ matiere.titre }}

  //- Niveaux
  .mb-3(v-if="niveaux && niveaux.length")
    span.f6.b {{$t('exercices.exercice-niveaux')}}
    .chips-list
      v-chip(v-for='(niveau, index) in niveaux' small :key='index') {{ niveau.titre }}
</template>

<style lang='sass' scoped>
.chips-list
  display: flex
  flex-wrap: wrap
  gap: 4px 12px
</style>
