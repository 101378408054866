const state = {
  showFusionCompte: false,
  showDevoirCreation: false,
  showGroupeCreation: false,
  showGroupeEdition: false,
  showAjoutCode: false,
  showAjoutEnfant: false,
  showElevesSelection: false,
  selectedEleves: null,
  showExercicesSelection: false,
  selectedExercices: [],
  // Calendrier
  showCreateEvent: false,
  showDeleteEvent: false,
  showCreateCalendar: false,
  selectedTableaux: null,
  showTableauBlancEdition: false,
  // ---
  // Tutorials
  showTutorialDialog: false,
  // -
  showAvatarSelection: false,
  showCodeConfirmation: false,
  showRetrieveIdentifications: false,
  showTableauSelection: false,
  showProfilEdition: false,
  backupData: null,
  tableauBlancToEdit: null,
  showTableauBlancSuppression: false,
  elemToDelete: null
}

const getters = {}

const actions = {}

const mutations = {
  setFusionCompte (state, payload) {
    state.showFusionCompte = payload
  },
  setDevoirCreation (state, payload) {
    state.showDevoirCreation = payload
  },
  setGroupeCreation (state, payload) {
    state.showGroupeCreation = payload
  },
  setGroupeEdition (state, payload) {
    state.showGroupeEdition = payload
  },
  setAjoutCode (state, payload) {
    state.showAjoutCode = payload
  },
  setAjoutEnfant (state, payload) {
    state.showAjoutEnfant = payload
  },
  setElevesSelection (state, payload) {
    state.showElevesSelection = payload
  },
  setSelectedEleves (state, payload) {
    state.selectedEleves = payload
  },
  setExercicesSelection (state, payload) {
    state.showExercicesSelection = payload
  },
  resetSelectedExercices (state) {
    state.selectedExercices.splice(0, state.selectedExercices.length)
  },
  resetSelectedEleves (state) {
    if (state.selectedEleves.length > 0) {
      state.selectedEleves.splice(0, state.selectedEleves.length)
    }
  },
  setSelectedExercices (state, payload) {
    state.selectedExercices.push(...payload)
  },
  setExactSelectedExercices (state, payload) {
    state.selectedExercices = payload
  },
  setCreateEvent (state, payload) {
    state.showCreateEvent = payload
  },
  setDeleteEvent (state, payload) {
    state.showDeleteEvent = payload
  },
  setCreateCalendar (state, payload) {
    state.showCreateCalendar = payload
  },
  setAvatarSelection (state, payload) {
    state.showAvatarSelection = payload
  },
  setCodeConfirmation (state, payload) {
    state.showCodeConfirmation = payload
  },
  setRetrieveIdentifications (state, payload) {
    state.showRetrieveIdentifications = payload
  },
  setProfilEdition (state, payload) {
    state.showProfilEdition = payload
  },
  setShowTableauSelection (state, payload) {
    state.showTableauSelection = payload
  },
  setSelectedTableaux (state, payload) {
    state.selectedTableaux = payload
  },
  setShowTutorialDialog (state, payload) {
    state.showTutorialDialog = payload
  },
  setTableauBlancToEdit (state, payload) {
    state.tableauBlancToEdit = payload
  },
  setBackupData (state, payload) {
    state.backupData = payload
  },
  setShowTableauBlancEdition (state, payload) {
    state.showTableauBlancEdition = payload
  },
  setShowTableauBlancSuppression (state, payload) {
    state.showTableauBlancSuppression = payload
  },
  setElemToDelete (state, payload) {
    state.elemToDelete = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
