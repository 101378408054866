export default {
  computed: {
    dateDebutFormatted: {
      set (val) { this.dateDebut = val },
      get () { return this.dateFormat(this.dateDebut) }
    },
    dateFinFormatted: {
      set (val) { this.dateFin = val },
      get () { return this.dateFormat(this.dateFin) }
    }
  },
  methods: {
    formatDate (data) {
      return new Date(data).toLocaleString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })
    },
    dateFormat (date) {
      if (!date) return null
      return this.$dayjs(date).format('DD-MM-YYYY')
    },
    getTimeZone (date) {
      const newDate = {}
      const adjustedDate = new Date(this.endDate.substring(0, 10))
      const timeDiff = adjustedDate.getTimezoneOffset() * 60000
      newDate.day = new Date(adjustedDate.valueOf() + timeDiff).toLocaleDateString('fr-CA', { day: 'numeric' })
      newDate.month = new Date(this.endDate).getMonth() + 1
      newDate.year = new Date(this.endDate).getFullYear()
      return newDate
    }
  }
}
