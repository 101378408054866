<script>
export default {
  name: 'retrieveIdentifications',
  data () {
    return {
      loading: false,
      finish: false,
      showError: false
    }
  },
  computed: {
    showRetrieveIdentifications: {
      set (val) { this.$store.commit('Dialogs/setRetrieveIdentifications', val) },
      get () { return this.$store.state.Dialogs.showRetrieveIdentifications }
    },
    showElevesSelection: {
      set (val) { this.$store.commit('Dialogs/setElevesSelection', val) },
      get () { return this.$store.state.Dialogs.showElevesSelection }
    },
    selectedEleves: {
      set (val) { this.$store.commit('Dialogs/setSelectedEleves', val) },
      get () { return this.$store.state.Dialogs.selectedEleves }
    }
  },
  watch: {
  },
  methods: {
    reset () {
      this.loading = false
      this.showError = false
      this.finish = false
      this.selectedEleves = []
      this.showRetrieveIdentifications = false
    },
    async sendIdentifiants () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/envoyerInfoSalleClasse.gql'),
          variables: { id: this.selectedEleves.id }
        }).then(({ data }) => {
          // console.log('LE RETOUR', data)
          if (data.envoyerInfoSalleClasse) {
            this.finish = true
            this.loading = false
          } else {
            /* eslint-disable-next-line */
            throw 'Erreur'
          }
        })
      } catch (e) {
        this.loading = false
        this.showError = true
        console.error(e)
      }
    }
  }
}
</script>
<template lang="pug">
v-dialog(v-model='showRetrieveIdentifications', max-width='600',content-class='custom-dialog', persistent)
  v-dialog(v-model='loading', hide-overlay, persistent, width='400')
    v-card(color='primary', dark)
      v-card-text
        div.dialog-text.pa4.secondary--text
          div.b.f4.mb3.tc Validation en cours...
          v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)
  v-card.light(v-if='!loading && !finish')
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click.native='reset', depressed, fab, x-large, color='light')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text Recevoir des identifiants
    v-card-text.relative
      div.dialog-text
        div Vous recevrez par courriel les identifiants des élèves de la classe {{selectedEleves && selectedEleves.titre ? selectedEleves.titre : null}}
    v-btn.secondary--text(@click='sendIdentifiants', color='primary', block, x-large, :disabled='!selectedEleves || (selectedEleves && selectedEleves.length < 1)')
      span.f4 Recevoir
</template>
<style lang='sass'>

</style>
