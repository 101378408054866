import axios from 'axios'

export default {
  isAvailable () {
    return 'geolocation' in navigator
  },
  getCurrentPosition () {
    if (!this.isAvailable()) return undefined
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition((success) => {
        const { latitude, longitude } = success.coords
        resolve({
          lat: latitude,
          lng: longitude
        })
      }, (error) => {
        resolve(error)
      })
    })
  },
  getAdressFromCoords ({ lat = 0, lng = 0 }) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?result_type=postal_code&latlng=${lat},${lng}&key=AIzaSyBzlLYISGjL_ovJwAehh6ydhB56fCCpPQw&language=fr`
    return axios.get(url)
      .then(({ results }) => results)
  }
}
