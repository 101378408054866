<script>
export default {
  name: 'ajoutCode',
  data () {
    return {
      loading: false,
      finish: false,
      showError: false,
      code: null,
      codeItem: null
    }
  },
  computed: {
    showAjoutCode: {
      set (val) { this.$store.commit('Dialogs/setAjoutCode', val) },
      get () { return this.$store.state.Dialogs.showAjoutCode }
    },
    validatedCode () {
      return !(this.code && this.code.length && (this.code.length === 8 || this.code.length === 6))
    }
  },
  watch: {
    code (newVal) {
      if (!newVal || newVal === null || newVal === '') {
        this.showError = false
      }
    }
  },
  methods: {
    reset () {
      this.code = null
      this.codeItem = null
      this.loading = false
      this.showError = false
      this.finish = false
      this.showAjoutCode = false
    },
    async envoiDemande () {
      this.loading = true
      this.showError = false
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/creerNuageItems.gql'),
          variables: {
            code: this.code
          }
        }).then(({ data }) => {
          if (!data.creerNuageItems) {
            throw data
          } else {
            this.codeItem = data.creerNuageItems
            this.loading = false
            this.finish = true
            // FETCH ITEMS NUAGE
          }
        })
      } catch (e) {
        this.loading = false
        this.showError = true
        // console.error(e)
      }
    },
    goToItem (item) {
      this.reset()
      this.$router.push({ name: 'bibliotheque', query: { document: item.boutiqueItem.slug, nuage: true } })
    },
    getImageList (item) {
      let images = {}
      if (item && item.images && item.images.length) {
        item.images.forEach(img => {
          // console.log('image', img)
          if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-couverture')) {
            images.coverImage = img
          } else if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-pictogramme')) {
            images.pictogramme = img
          }
        })
        // console.log('image', images)
      }
      return images
    }
  }
}
</script>
<template lang="pug">
v-dialog(v-model='showAjoutCode', max-width='600',content-class='custom-dialog', persistent, scrollable)
  v-dialog(v-model='loading', hide-overlay, persistent, width='400')
    v-card(color='primary', dark)
      v-card-text
        div.dialog-text.pa4.secondary--text
          div.b.f4.mb3.tc Validation en cours...
          v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)

  v-card.light(v-if='!loading && !finish')
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='showAjoutCode = !showAjoutCode', @click.native='reset', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text Ajouter un code
    v-card-text.relative
      div.dialog-text.secondary--text
        div Pour ajouter un nouveau cahier numérique à votre espace, veuillez entrer le code qui vous a été fourni.

    v-card-text.relative
      v-text-field.code-input(light, color='secondary', placeholder='Code à 6 ou 8 caractères', solo, hide-details, v-model='code', clearable, @keyup.enter='envoiDemande')
      //- .arrow-icon__wrapper
        font-awesome-icon.f1.arrow-icon.animated.bounce.delay-2s(:icon="['fas', 'arrow-alt-down']")
      v-alert.light.animated.fadeInDown(v-if='showError && code', dense, text, type='error', background-color='light') Nous n'avons pas trouvé de ressources correspondant à ce code.
    v-btn(@click='envoiDemande', color='primary', block, x-large, :disabled='validatedCode')
      span.f4 Envoyer

  v-card.light.code-item(v-else-if='!loading && finish')
    template(v-if='codeItem')
      //- pre {{codeItem}}
      v-hover(v-slot:default="{ hover }")
        v-btn.dialog-button(@click='showAjoutCode = !showAjoutCode', @click.native='reset', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
          font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title.secondary.white--text(style='margin-bottom:0;') Code ajouté
      v-card-text.dark--text
        //- MESSAGE LIÉS À ACTIVATION CODE
        v-alert.code-message.mt4(type='success', border='left', text, outlined)
          div.secondary--text
            div.b.f4 {{codeItem.paquet.titre}}
            div.mt2(v-html='codeItem.paquet.description', style='font-size:14px;')
        div.dialog-text
          div.mb3.f6.tc.i.flex.items-center
            v-divider
            span.mh3 {{codeItem.items.length === 1 ? 'Le document suivant a été ajouté dans votre espace parent' : 'Voici les documents qui ont été ajoutés dans votre espace parent'}}
            v-divider
          v-list.tl.product-list(style='background-color:transparent;')
            div.flex.mb2(v-for='(item, index) in codeItem.items', style='overflow: hidden; border-radius:10px;')
              v-img(v-if='getImageList(item.boutiqueItem).coverImage', :src='getImageList(item.boutiqueItem).coverImage.medium_image', :lazy-src='getImageList(item.boutiqueItem).coverImage.thumbnail')
              v-list-item(style='background-color:white;')
                v-list-item-content
                  div(v-if='getImageList(item.boutiqueItem).pictogramme')
                    v-avatar(size='30')
                      v-img(:src='getImageList(item.boutiqueItem).pictogramme.imageUrl')
                  v-list-item-subtitle.mt1 Cahier numérique
                  v-list-item-title {{item.boutiqueItem.titre}}
                v-list-item-action
                  //- div.f7.i Disponible dès maintenant
                  v-spacer
                  v-btn(@click='goToItem(item)', x-small, depressed, color='secondary') Voir le document
                  //- v-btn(@click='goToItem(item)', x-small, flat, color='secondary') Voir le document
    v-btn(@click='showAjoutCode = !showAjoutCode', @click.native='reset', color='primary', block, x-large)
      span.f4 Fermer
</template>
<style lang='sass'>
.code-message
  p
    &:last-child
      margin-bottom: 0
.code-item
  .product-list
    .v-list-item__content
      .v-list-item__title
        white-space: normal
    .v-image
      max-width: 80px
</style>
