<script>
import ChatControls from '@/mixins/chatControls.js'

export default {
  name: 'NavigationSidebar',
  mixins: [ChatControls],
  components: {
  },
  props: {
    // words: { type: Array, required: true },
  },
  data () {
    return {
      cahierMenu: false,
      tableauxMenu: false
    }
  },
  computed: {
    mobileTemplate: {
      get () { return this.$store.state.App.mobileTemplate },
      set (val) { this.$store.commit('App/setMobileTemplate', val) }
    },
    pendingRequestsDialog: {
      get () { return this.$store.state.Notifications.pendingRequestsDialog },
      set (val) { this.$store.commit('Notifications/setPendingRequestsDialog', val) }
    },
    permanentMenuConditions () {
      let val = true
      if (this.$store.state.App.mobileTemplate) {
        val = false
      }
      return val
    },
    expandMenuConditions () {
      let val = false
      if ((this.$store.state.App.windowSize.width > 900 && this.$store.state.App.windowSize.width < 1200) ||
         (this.$route && this.$route.name && (this.$route.name.includes('bibliotheque') || this.$route.name.includes('calendrier') || this.$route.name.includes('lecture') || this.$route.name.includes('exercice')))) {
        val = true
      }
      return val
    }
  },
  watch: {
    '$route' (newVal) {
      if (newVal && newVal.name && (newVal.name.includes('bibliotheque') || newVal.name.includes('documents'))) {
        this.cahierMenu = true
      } else if (newVal && newVal.name && (newVal.name.includes('tableau'))) {
        this.tableauxMenu = true
      }
    }
  }
}
</script>

<template lang='pug'>

v-navigation-drawer.navigation-sidebar(
  app
  :permanent='permanentMenuConditions'
  dark
  :mini-variant='expandMenuConditions'
  mini-variant-width='80'
  width='375'
  :expand-on-hover='expandMenuConditions'
  :class='{"minified__side-menu" : expandMenuConditions}',
  disable-route-watcher
)
  perfect-scrollbar.navigation-scroller(:options='{wheelPropagation: false, suppressScrollX: true}')

    div.navigation__inner-content.relative
      router-link.no-underline(to='/', v-if='$store.state.App.windowSize.width < 1200 && $store.state.App.windowSize.width > 900')
        v-list.list-header(dense)
          v-list-item()
            v-list-item-avatar
              img(src='~@/assets/images/logos/dokoma-icon.svg', width='50')
            v-list-item-content
              v-list-item-title Espace Parent
      div.nav-header(v-else-if='!$store.state.App.mobileTemplate')
        span.nav-title(v-if='!expandMenuConditions') Espace parent
      v-list.main-menu__list(dense)
        v-list-item.no-underline(to='/profil', exact, v-if='$store.state.User.profile', two-line)
          v-list-item-avatar.dark--text(color='light')
            img(v-if='$store.state.User.profile && $store.state.User.profile.avatar', :src="$store.state.User.profile.avatar.imageUrl")
            span.f6(v-else) {{$store.state.User.profile.info.prenom[0]}}.{{$store.state.User.profile.info.nom[0]}}.
          v-list-item-content
            v-list-item-title(v-if='$store.state.User.profile.info', style='font-size: 18px') {{$store.state.User.profile.info.prenom}} {{$store.state.User.profile.info.nom}}
            div.parent-tag.f6(v-if='$store.state.User.profile.info') @{{$store.state.User.profile.info.username}}
      v-divider.mv3.white.o-50
      v-list.main-menu__list(rounded, dense)

        v-list-item(to='/', exact, active-class='menu-link--active')
          v-list-item-icon
            font-awesome-icon.f3(:icon="['fad', 'apple-alt']")
          v-list-item-content
            v-list-item-title Accueil

        v-list-group(v-model='tableauxMenu')
          template(v-slot:activator)
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'chalkboard-teacher']")
            v-list-item-content()
              v-list-item-title()
                span.pr2 Tableaux blancs
          v-list-item(:to="{ name: 'tableau'}", exact, active-class='menu-link--active')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'chalkboard-teacher']")
            v-list-item-content()
              v-list-item-title Mes tableaux blancs
          v-list-item(:to="{ name: 'tableauxAchats___' }" exact active-class='menu-link--active')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'award']")
            v-list-item-content()
              v-list-item-title
                span.pr2 Mes achats
                v-chip(x-small, color='primary') nouveau
        v-list-item(to='/bibliotheque', exact, active-class='menu-link--active')
          v-list-item-icon
            font-awesome-icon.f4(:icon="['fad', 'books']")
          v-list-item-content()
            v-list-item-title Bibliothèque numérique
        //- v-list-group(v-model='cahierMenu')
          template(v-slot:activator='')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'book']")
            v-list-item-content()
              v-list-item-title Bibliothèque numérique
          v-list-item(to='/bibliotheque', exact, active-class='menu-link--active')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'books']")
            v-list-item-content()
              v-list-item-title Tous mes cahiers
          v-list-item(to='/lectures', exact, active-class='menu-link--active')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'book-reader']")
            v-list-item-content()
              v-list-item-title Listes de lecture

        //- Bloc Exercices en lignes
        v-list-group
          template(v-slot:activator)
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'pen']")
            v-list-item-content()
              v-list-item-title Leçons et devoirs interactifs
          v-list-item(:to='{ name: "exercices" }', exact, active-class='menu-link--active')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'books']")
            v-list-item-content()
              v-list-item-title Bibliothèque
          v-list-item(:to='{ name: "devoirEdition" }', exact, active-class='menu-link--active')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'book']")
            v-list-item-content()
              v-list-item-title Créer une leçon ou un devoir interactif
          v-list-item(:to='{ name: "devoirs___" }', exact, active-class='menu-link--active')
            v-list-item-icon
              font-awesome-icon.f4(:icon="['fad', 'book-reader']")
            v-list-item-content()
              v-list-item-title Les leçons et les devoirs envoyés

        v-list-item(to='/enfants', exact, active-class='menu-link--active')
          v-list-item-icon
            font-awesome-icon.f3(:icon="['fad', 'backpack']")
          v-list-item-content
            v-list-item-title Espace scolaire de mes enfants

        v-list-item(to='/calendrier', exact, active-class='menu-link--active')
          v-list-item-icon
            font-awesome-icon.f3(:icon="['fad', 'calendar-alt']")
          v-list-item-content
            v-list-item-title Calendrier

        v-list-item(@click='goChat')
          v-list-item-icon
            v-badge.vuetify-badge(v-model='$store.state.App.newMess && !expandMenuConditions && $store.state.App.windowSize.width > 900', color='red', icon='mdi-exclamation-thick', overlap)
              font-awesome-icon.f3(:icon="['fad', 'comment-smile']")
          v-list-item-content
            v-list-item-title Clavardage
          v-badge.vuetify-badge.animated.pulse.infinite(v-model='$store.state.App.newMess && expandMenuConditions', color='red', icon='mdi-exclamation-thick', overlap)

        v-list-item(active-class='menu-link--active', href='https://boutique.dokoma.com')
          v-list-item-icon
            font-awesome-icon.f4(:icon="['fad', 'shopping-cart']")
          v-list-item-content
            v-list-item-title
              span.pr2 Boutique

        //- v-list-item(to='/historique', exact, disabled)
          v-list-item-icon
            font-awesome-icon.f3(:icon="['fad', 'history']")
          v-list-item-content
            v-list-item-title Mes achats passés

        //- v-list-item(to='/ressources', exact, disabled)
          v-list-item-icon
            font-awesome-icon.f3(:icon="['fad', 'hands-helping']")
          v-list-item-content
            v-list-item-title() Ressources scolaires
      div.flex-grow-1
      .navigation-sidebar__calltoaction(v-if='!expandMenuConditions')
        //- v-btn.mb2(block, outlined,rounded, depressed, dark, @click='$vuetify.theme.dark = !$vuetify.theme.dark') switch theme
        v-btn(@click='pendingRequestsDialog = true', block,rounded, depressed, dark, color='primary', :disabled='!$store.state.User.relationEnAttente.length')
          font-awesome-icon.f4.mr2(:icon="['fad', 'bell']")

          | Invitations en attente

      v-list(v-else, dense, rounded)
        v-list-item(@click='pendingRequestsDialog = true', exact)
          v-list-item-icon
            v-badge.vuetify-badge(v-model='($store.state.User.relationEnAttente && $store.state.User.relationEnAttente.length) && expandMenuConditions && $store.state.App.windowSize.width > 900', color='red', icon='mdi-exclamation-thick', overlap)
              font-awesome-icon.f3(:icon="['fad', 'bell']")
          v-list-item-content
            v-list-item-title Invitations en attente
          v-badge.vuetify-badge.animated.pulse.infinite(v-model='$store.state.App.newMess && !expandMenuConditions', color='red', icon='mdi-exclamation-thick', overlap)
</template>

<style lang='sass'>
.navigation-sidebar
  .navigation-scroller
    .main-menu__list
      .v-list-group
        margin-bottom: 10px
        .v-list-group__header
          margin-bottom: 0 !important
          &.v-list-item.v-list-item--active
            background-color: rgba(255,255,255,0.15) !important
            border-radius: 20px 20px 0 0 !important
            border-bottom: solid 1px rgba(255,255,255,0.15) !important
            &::before
              border-radius: 20px 20px 0 0 !important
        .v-list-group__items
          overflow: hidden
          background-color: rgba(255,255,255,0.15) !important
          border-radius: 0 0 20px 20px
          padding: 10px
          .v-list-item
            margin-bottom: 0
            &:last-child
              margin-bottom: 0 !important
            .v-list-item__title
              line-height: 1 !important

  &.minified__side-menu
    .navigation-scroller
      .main-menu__list
        .v-list-group
          .v-list-group__items
            padding: 0px
            .v-list-item
              border-radius: 0 !important
              margin-bottom: 0 !important
              &::before
                border-radius: 0 !important

  &.v-navigation-drawer--is-mouseover
    .navigation-scroller
      .main-menu__list
        .v-list-group
          .v-list-group__items
            padding: 10px
            .v-list-item
              border-radius: 20px !important
              margin-bottom: 10px !important
              &::before
                border-radius: 20px !important
              &:last-child
                margin-bottom: 0 !important
</style>
<style lang='sass' scoped>
  @import 'src/styles/layout/_sidebar'
  // .navigation-sidebar
    // background-color: var(--v-secondary-base) !important
</style>
