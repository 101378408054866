<script>
export default {
  name: 'EditTableauBlanc',
  props: {
    tableauData: { required: false, type: Object, default: () => {} },
    eleve: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
      loading: false,
      titre: this.tableauBlancToEdit && this.tableauBlancToEdit.titre ? this.tableauBlancToEdit.titre : null,
      description: this.tableauBlancToEdit && this.tableauBlancToEdit.description ? this.tableauBlancToEdit.description : null
    }
  },
  components: {
  },
  computed: {
    validatedForm () {
      if (this.titre) {
        return true
      } else {
        return false
      }
    },
    tableauBlancToEdit: {
      get () { return this.$store.state.Dialogs.tableauBlancToEdit },
      set (val) { this.$store.commit('Dialogs/setTableauBlancToEdit', val) }
    },
    showTableauBlancEdition: {
      get () { return this.$store.state.Dialogs.showTableauBlancEdition },
      set (val) { this.$store.commit('Dialogs/setShowTableauBlancEdition', val) }
    }
  },
  watch: {
    showTableauBlancEdition (newValue) {
      if (newValue) {
        this.onDialogOpen()
      }
    }
  },
  methods: {
    onDialogOpen () {
      this.titre = this.tableauBlancToEdit.titre
      this.description = this.tableauBlancToEdit.description
    },
    clearDialog () {
      this.showTableauBlancEdition = false
      this.tableauBlancToEdit = null
    },
    async saveTableauV2 () {
      // V1: updateCalendrierElement for each CCE, updateEnregistrementDossier, updateEnregistrementTableauBlanc, ...
      // V2: Only call the updateCalendrierElement
      const id = this.tableauBlancToEdit?.identifiant
      if (!id) {
        return null
      }

      try {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/updateCalendrierElement.gql'),
          variables: {
            id,
            descendance: true,
            attrs: {
              titre: this.titre,
              description: this.description
            }
          }
        })

        if (!data.updateCalendrierElement.id || data.updateCalendrierElement.errors) {
          this.failed()
        } else {
          this.successed()
        }
      } catch (e) {
        console.error(e)
        this.failed()
      }
    },
    successed () {
      this.showTableauBlancEdition = false
      this.$store.commit('App/setShowSnackbarGlobal', true)
      this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: 'Erreur', description: 'Erreur lors de la modification du titre tableau blanc.' })
      this.loading = false
    },
    failed () {
      this.showTableauBlancEdition = false
      this.$store.commit('App/setShowSnackbarGlobal', true)
      this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: 'Erreur', description: 'Erreur lors de la modification du titre tableau blanc.' })
      this.loading = false
    }
  }
}
</script>

<template lang='pug'>
div(v-if='showTableauBlancEdition')
  v-dialog(v-model='showTableauBlancEdition', max-width='600', content-class='custom-dialog', persistent, light)
    v-card.light()
      v-hover(v-slot:default="{ hover }")
        v-btn.dialog-button(@click='clearDialog', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
          font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title.secondary.white--text Modifier le titre du tableau
      v-card-text.relative
        v-form(ref='tableauUpdate')
          div
            label Titre du tableau
            v-text-field(placeholder='Titre du tableau', solo, flat, outlined, hide-details, v-model='titre', clearable)
          div.mt2
            label Description
            v-textarea(placeholder='Description', solo, flat, outlined, hide-details, v-model='description', clearable)
      v-btn(@click='saveTableauV2', color='secondary', depressed, block, x-large, :disabled='!validatedForm || loading')
        span.f4 Modifier
</template>
<style lang='sass'>

</style>
