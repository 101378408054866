<script>
export default {
  name: 'fallbackTutorial',
  components: {
  },
  data () {
    return {
    }
  },
  watch: {
  },
  computed: {
    showTutorialDialog: {
      set (val) { this.$store.commit('Dialogs/setShowTutorialDialog', val) },
      get () { return this.$store.state.Dialogs.showTutorialDialog }
    }
  },
  methods: {
  }
}
</script>
<template lang='pug'>
  div
    v-sheet.step-introduction(:color='$store.state.Preferences.darkMode ? "light" : "white"', light, rounded)
      h1.step-title Aide
      div.step-description
        p Il n'y a pas encore d'aide spécifique pour cette page et ces fonctions.
        p.i.o-50() L'espace enseignant est en constante évolution. Rendez-vous sur l'accueil pour clavarder avec nous, si vous avez des questions. Il nous fera plaisir de vous répondre.
    //- div.step-content(style='min-height: unset')
      v-list.intro-list()
        v-list-item(@click='launchTour()')
          v-list-item-avatar(color='accent', size='50')
            font-awesome-icon.fa-2x(:icon="['fad', 'binoculars']")
          v-list-item-content
            v-list-item-title Lancer la visite guidée
      //- div.flex.items-center.justify-center
        v-checkbox(v-model='hideTutorials', label='Masquer les tutoriels', color='secondary')
</template>
<style lang='sass'>

</style>
