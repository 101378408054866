<script>
export default {
  name: 'ajoutEnfant',
  components: {
  },
  data () {
    return {
      quantity: 1,
      loading: false,
      finish: false,
      showError: false,
      step: 1,
      customNamesList: [],
      generatedNames: false
    }
  },
  computed: {
    showAjoutEnfant: {
      set (val) { this.$store.commit('Dialogs/setAjoutEnfant', val) },
      get () { return this.$store.state.Dialogs.showAjoutEnfant }
    }
  },
  methods: {
    async sendCreationRequest () {
      this.loading = true
      // let typeRel = 'parent_eleve'
      console.log('this.quantity', this.quantity)
      console.log('customNAmesList', this.customNamesList)
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/remplirSalleClasse.gql'),
          client: 'v2',
          variables: {
            total: parseInt(this.quantity),
            detail: !this.generatedNames ? this.customNamesList : null
          }
        }).then(({ data }) => {
          if (!data.remplirSalleClasse) {
            Error('erreur remplir groupe')
          } else {
            this.loading = false
            this.finish = true
            this.showAjoutEnfant = false
            this.$store.commit('App/setShowSnackbarGlobal', true)
            setTimeout(() => {
              this.reset(true)
              this.$store.commit('User/setRefetchEnfant', true)
            }, 1000)
            this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: this.$t('alerte.succes'), description: this.$t('alerte.succes-creation-eleve') })
          }
        })
      } catch (e) {
        this.loading = false
        this.showError = true
      }
    },
    reset () {
      this.loading = false
      this.showError = false
      this.finish = false
      this.quantity = 1
    },
    clearDialog () {
      this.reset()
      this.showAjoutEnfant = false
    },
    updateQuantityTable () {
      const emptyName = { prenom: null, nom: null }
      if (this.customNamesList && this.customNamesList.length === 0) {
        for (let i = 0; i < this.quantity; i++) {
          this.customNamesList.push(Object.assign({}, emptyName))
        }
      }
    },
    stepControl (val) {
      if (val === 'forward') {
        this.updateQuantityTable()
        this.step++
      } else if (val === 'backward') {
        this.customNamesList = []
        this.step--
      }
    }
  }
}
</script>

<template lang='pug'>
v-dialog(v-model='showAjoutEnfant', max-width='450',content-class='custom-dialog', persistent)
  v-dialog(v-model='loading', hide-overlay, persistent, width='400')
    v-card(color='primary', dark)
      v-card-text
        div.dialog-text.pa4.secondary--text
          div.b.f4.mb3.tc Validation en cours...
          v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)

  v-card.light(v-if='!finish')
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='showAjoutEnfant = !showAjoutEnfant', @click.native='reset', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text Création de compte élève
    v-window(v-model='step')
      v-window-item(:value='1')
        v-card-text.dark--text
          div.dialog-text.mt2()
            div.f5.mb2.b Si vos enfants n'ont pas de compte élève, vous pouvez en créer ici.
            div.i.f6 Vous recevrez les identifiants et une confirmation par courriel.
        v-card-text.relative
          v-text-field.code-input.w-50(light, color='secondary', placeholder='1', solo, hide-details, v-model='quantity', clearable, maxlength='2', max='99', min='1')
          v-checkbox(v-model='generatedNames', label='Générer des noms automatiquement')
      v-window-item(:value='2')
        div.dialog-text.mx-2.mt2()
          table.w-100
            tr
              th.w-50.tl Prenom
              th.w-50.tl Nom
            tr(v-for='(items, index) in customNamesList')
              td.w-40
                v-text-field.mb1.mr1(placeholder='Prenom', hide-details, v-model='customNamesList[index].prenom', dense)
              td.w-40
                v-text-field.mb1.ml1(placeholder='Nom', hide-details, v-model='customNamesList[index].nom', dense)
      v-btn(v-if='step > 1', @click='stepControl("backward")', block, text, x-large) Retour
      v-btn(v-if='step < 2 && !generatedNames', @click='stepControl("forward")', color='secondary', depressed, block, x-large) Étap suivant
      v-btn(v-if='step === 2 || generatedNames', @click='sendCreationRequest', color='secondary', depressed, block, x-large, :disabled='!quantity || quantity < 1', :dark='!quantity || quantity < 1')
        span.f4 Envoyer

  v-card.light(v-else-if='finish')
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='$emit("closeDialog")', @click.native='reset', depressed, fab, x-large, color='light')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.primary.white--text {{quantity > 1 ?'Les comptes ont bien été créés.' : 'Le compte a bien été créé.'}}
    v-card-text.dark--text
      div.dialog-text
        div.f5.mb2.b Vous recevrez une confirmation par courriel avec toutes les informations nécessaires.
        div.i.f6 Vous devrez rafraîchir votre page pour voir les comptes enfants apparaître dans votre espace parent.
</template>
<style lang='sass'>

</style>
