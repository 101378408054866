<script>

export default {
  name: 'nightMode',
  props: {
    mini: { required: false, type: Boolean, default: false },
    fullButton: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
    }
  },
  computed: {
    darkMode: {
      get () { return this.$store.state.Preferences.darkMode },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'darkMode', data: val })
      }
    },
    themeTooltipContent () {
      if (this.$store.state.Preferences.darkMode) {
        return 'Mode clair'
      } else {
        return 'Mode sombre'
      }
    }
  }
}
</script>

<template lang='pug'>
  .night-mode-index
    v-hover(v-slot:default='{ hover }')
      v-btn.mr2(
        :fab='!mini && !fullButton', :large='!mini'
        :icon='mini && !fullButton', :x-small='mini && !fullButton',
        rounded
        depressed
        @click='darkMode = !darkMode'
        color='secondary'
        style='overflow: hidden'
        :retain-focus-on-click='false'
        v-tooltip.bottom="{ content: themeTooltipContent , offset:'5px', enabled: !fullButton }"
      )
        transition(name='custom-classes-transition', enter-active-class='animated faster fadeInUp', leave-active-class='animated faster fadeOutDown', mode='out-in')
          div(:key='$store.state.Preferences.darkMode')
            template(v-if='!$store.state.Preferences.darkMode')
              div.w-100.h-100
                font-awesome-icon.f3.white--text(:icon="['fad', 'moon-stars']", :class="{ 'animated pulse infinite': hover, 'f3' : !mini, 'f4' : mini }")
                span.ml2.mb2(v-if='fullButton') Mode sombre
            template(v-else)
              div.w-100.h-100
                font-awesome-icon.f3.amber--text(:icon="['fad', 'sun']", :class="{ 'fa-spin infinite animated slower': hover, 'f3' : !mini, 'f4' : mini }")
                span.ml2.mb2(v-if='fullButton') Mode clair

</template>
<style lang='sass'>
</style>
