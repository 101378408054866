<script>
export default {
  name: 'Footer',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  apollo: {
  },
  methods: {
  }
}
</script>

<template lang="pug">
  .footer-index
    div
      img(src='@/assets/images/logos/dokoma-white.svg', height='20px')
      div Droits d'auteurs Letabar {{ new Date().getFullYear() }}
    div.flex-grow-1
    div.flex.flex-column.items-end
      a.mt2.ml5.white--text.f5(href='https://dokoma.statuspage.io/', target='_blank') État des serveurs
    //- div.flex.flex-column.items-end
      div.white--text.f5 Besoin d'aide?
      a.tr.white--text(href='https://blahblah.dokoma.com/clavardage', target='_blank') Venez discuter
</template>

<style lang='sass'>
  @import 'src/styles/layout/_footer'
</style>
