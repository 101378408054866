<script>
import ChatControls from '@/mixins/chatControls.js'
import LogUser from '@/mixins/login.js'

import NightMode from '@/components/home/options/nightMode.vue'

export default {
  name: 'LayoutTopBanner',
  mixins: [LogUser, ChatControls],
  components: {
    NightMode
  },
  props: {
    // words: { type: Array, required: true },
  },
  data () {
    return {
    }
  },
  apollo: {
  },
  computed: {
    showTutorialDialog: {
      set (val) { this.$store.commit('Dialogs/setShowTutorialDialog', val) },
      get () { return this.$store.state.Dialogs.showTutorialDialog }
    },
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    },
    notificationsSidebar: {
      get () { return this.$store.state.App.notificationsSidebar },
      set (val) { this.$store.commit('App/setNotificationsSidebar', val) }
    },
    years () {
      return this.$store.state.User.profile.years
    },
    expandMenuConditions () {
      let val = false
      if ((this.$store.state.App.windowSize.width > 900 && this.$store.state.App.windowSize.width < 1200) || (this.$route && this.$route.name && (this.$route.name.includes('bibliotheque') || this.$route.name.includes('calendrier')))) {
        val = true
      }
      return val
    },
    isNumerika () {
      return window.location.hostname.includes('numerikaeditions')
    }
  },
  methods: {
    async logout () {
      let domain = window.location.hostname
      if (domain !== 'localhost') {
        const data = domain.split('.')
        data[0] = ''
        domain = data.join('.')
      }
      this.$store.dispatch('User/logOut')
      this.$cookies.remove('ClassDokomaToken', '/', domain)
      this.$cookies.remove('ClassUser', '/', domain)
      window.location.reload()
    }
  }
}
</script>

<template lang='pug'>
v-app-bar.light--text.secondary-navbar#secondaryBar(fixed, dense, color='dark', height='40', :class='{"sidebar-override" : $store.state.Layout.sideNav, "sidebar-temporary" : expandMenuConditions}', style='z-index:100 !important;')
  div.space-filler(style='width:100px;')
  template(v-if='$store.state.User.connected')
    v-btn.mr3.ml3(@click='logout', icon, x-small, color='light', v-tooltip.bottom="{ content: 'Déconnexion', offset:'10px'}")
      v-hover(v-slot:default='{ hover }')
        font-awesome-icon.f4(:icon="['fad', 'power-off']", :class="{ 'animated flash infinite red--text': hover }")
  div.flex-grow-1
  router-link.no-underline.site-logo.tc(to='/', style='line-height:1;')
    img(v-if='isNumerika', src='~@/assets/images/logos/logo.svg', height='180')
    img(v-else src='~@/assets/images/logos/dokoma-white.svg', height='20')
  div.flex-grow-1
  div.options__wrapper(v-if='$store.state.User.connected')
    v-btn(@click='showTutorialDialog = !showTutorialDialog', icon, x-small, color='light', v-tooltip.bottom="{ content: 'Assistant', offset:'10px'}")
      font-awesome-icon.f4(:icon="['fad', 'question-circle']")
    v-badge.vuetify-badge(@click='notificationsSidebar = !notificationsSidebar', v-model='$store.state.User.relationEnAttente && $store.state.User.relationEnAttente.length', color='red', icon='mdi-exclamation-thick', overlap)
      v-btn.ml3(@click='notificationsSidebar = !notificationsSidebar', icon, x-small, color='light', v-tooltip.bottom="{ content: 'Notifications', offset:'10px'}")
        v-hover(v-slot:default='{ hover }')
          font-awesome-icon.f4(:icon="['fad', 'bell']", :class="{ 'animated swing infinite deep-orange--text darken-3': hover }")

    v-badge.vuetify-badge(@click='goChat', v-model='$store.state.User.relationEnAttente && $store.state.User.relationEnAttente.length', color='red', icon='mdi-exclamation-thick', overlap)
      v-btn.ml3(@click='goChat', icon, x-small, color='light', v-tooltip.bottom="{ content: 'Clavardage', offset:'10px'}")
        v-hover(v-slot:default='{ hover }')
          font-awesome-icon.f4(:icon="['fad', 'comment-smile']", :class="{ 'animated wobble infinite primary--text': hover }")
    night-mode.ml3(:mini='true')

  div.space-filler(v-else, style='width:100px;')
</template>

<style lang='sass'>
.secondary-navbar
  z-index: 6 !important
  &.sidebar-override
    left: 0 !important
  &.sidebar-temporary
    .v-toolbar__content
      padding-left: 14px !important
  .v-toolbar__content
    padding: 10px $responsive-gutter-small
  .options__wrapper
    display: flex
    align-items: center
    justify-content: flex-end
    width: 100px
  .v-input
    border-radius: 0
    flex: none
    width: 150px
    display: block
    .v-input__control
      min-height: 40px !important
    .v-select__selections input
      display: none
    .v-input__slot
      width: 150px
      min-height: 40px !important
      box-shadow: none !important
    .v-input__append-inner
      .v-input__icon
        width: 20px
        min-width: 20px
  .alpha-tag
    filter: drop-shadow(0 0 14px #0000007a)
    position: absolute
    bottom: -25px
    left: 10px
    transform: rotate(-10deg)
  @media all and (max-width: $medium)
    .v-toolbar__content
      padding: 10px $responsive-gutter-small !important
    .alpha-tag
      position: relative
      bottom: unset
      left: unset
      width: 35px
    .v-input
      flex: none
      width: 110px
      display: block
      .v-input__control
        width: 100%
        min-height: 40px !important
      .v-select__selections input
        display: none
      .v-input__slot
        width: 100%
        min-height: 40px !important
        padding: 0 5px !important
        font-size: 13px

</style>
