<script>
export default {
  name: 'feedbackSnackbar',
  computed: {
    snackBarState: {
      get () {
        return this.$store.state.App.showSnackbarGlobal
      },
      set (val) {
        this.$store.commit('App/setShowSnackbarGlobal', val)
      }
    },
    snackBarStateContent: {
      get () {
        return this.$store.state.App.snackbarGlobalContent
      },
      set (val) {
        this.$store.commit('App/setSnackbarGlobalContent', val)
      }
    }
  },
  watch: {
  }
}
</script>

<template lang="pug">
  .feedback-snackbar
    v-snackbar(:color='snackBarStateContent.type', bottom, v-model='snackBarState', :timeout='5000', fixed)
      div.flex.items-center(v-if='snackBarStateContent')
        div.mr2()
          font-awesome-icon(:icon="[snackBarStateContent.type === 'error' ? 'fad' : 'fas', snackBarStateContent.type === 'error' ? 'exclamation-circle' : 'check']")
        div
          div.f7.i(v-if='snackBarStateContent.type === "success"') Succès
          div.f7.i(v-else-if='snackBarStateContent.type === "error"') Erreur
          div.f6(v-if='snackBarStateContent.description') {{snackBarStateContent.description}}
      template(v-slot:action='{ attrs }')
        v-btn(icon, small, v-bind='attrs', @click='snackBarState = false')
          font-awesome-icon(:icon="['fas', 'times']")
</template>

<style>
</style>
