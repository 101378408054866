<script>
import FormatDate from '@/mixins/formatDate.js'
export default {
  name: 'DemandeAjout',
  mixins: [FormatDate],
  data () {
    return {
      loadRel: [],
      finishRel: [],
      error: false,
      selectClasse: null,
      nameClasse: null,
      showGroupe: false,
      showSuccess: false,
      showSuccessRefuse: false,
      activeRela: null
    }
  },
  computed: {
    pendingRequestsDialog: {
      get () { return this.$store.state.Notifications.pendingRequestsDialog },
      set (val) { this.$store.commit('Notifications/setPendingRequestsDialog', val) }
    },
    relationEnAttente () {
      return this.$store.state.User.relationEnAttente
    },
    allClasses () {
      const temp = [{ id: 'new', titre: 'Créer une nouvelle classe' }]
      if (this.$store.state.User.groupes.length > 0) {
        temp.push(...this.$store.state.User.groupes)
      }
      return temp
    }
  },
  methods: {
    async acceptRelation (rel, index) {
      this.error = false
      this.activeRela = rel
      this.$set(this.loadRel, this.activeRela.id, true)
      let classId = this.selectClasse
      if (this.selectClasse === 'new') {
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/v2/createSalleClasse.gql'),
            client: 'v2',
            variables: {
              attrs: {
                statut: 'ACTIF',
                titre: this.nameClasse,
                nature: 'MAISON'
              }
            }
          }).then(({ data }) => {
            if (!data.createSalleClasse || data.createSalleClasse.errors) {
              // eslint-disable-next-line no-throw-literal
              throw data.createSalleClasse.errors
            } else {
              // on peut reloader user mais on attends
              classId = data.createSalleClasse.id
            }
          })
        } catch (e) {
          this.$set(this.loadRel, this.activeRela.id, false)
          this.showGroupe = false
          this.error = true
          console.error(e)
        }
      }
      if (!this.error) {
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/v2/approuverSalleClasseUsagerDemande.gql'),
            variables: { id: this.activeRela.id }
          }).then(async ({ data }) => {
            if (data && data.approuverSalleClasseUsagerDemande && !data.approuverSalleClasseUsagerDemande.errors) {
              await this.addEnfantToGroupe(classId, this.activeRela.emetteur.id)
            } else {
              throw data.approuverSalleClasseUsagerDemande.errors ? data.approuverSalleClasseUsagerDemande.errors : 'error'
            }
          })
        } catch (e) {
          this.$set(this.loadRel, this.activeRela.id, false)
          this.showGroupe = false
          this.error = true
          console.error(e)
        }
      }
    },
    async refuseRelation (rel, index) {
      this.$set(this.loadRel, rel.id, true)
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/refuserSalleClasseUsagerDemande.gql'),
          variables: {
            id: rel.id
          }
        }).then(({ data }) => {
          if (data.destroySalleClasseUsagerDemande && !data.destroySalleClasseUsagerDemande.errors) {
            this.closeNotif(rel)
          } else {
            throw data.destroySalleClasseUsagerDemande.errors ? data.destroySalleClasseUsagerDemande.errors : 'error'
          }
        })
      } catch (e) {
        this.error = true
        console.error(e)
      }
    },
    async addEnfantToGroupe (rel) {
      // - on ajouter l'enfant au groupe de base
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/ajouterUsagerSalleClasse.gql'),
          variables: {
            id: this.$store.state.User.groupes[0].id,
            usagers: [rel.emetteur.id]
          }
        }).then(({ data }) => {
          if (!data.ajouterUsagerSalleClasse || data.ajouterUsagerSalleClasse.errors) {
            throw !data.ajouterUsagerSalleClasse ? 'error ajout groupe ' + this.$store.state.User.groupes[0].id + ' emetteur ' + rel.emetteur.id : data.ajouterUsagerSalleClasse.errors
          } else {
            this.closeNotif(rel)
          }
        })
      } catch (e) {
        console.error('erreur ajout to groupe', e)
      }
    },
    stateLoad (rel) {
      return this.loadRel[rel]
    },
    closeNotif (rel) {
      this.showGroupe = false
      this.$set(this.loadRel, rel, false)
      this.$set(this.finishRel, rel, true)
      this.activeRela = null
      this.error = false
      this.$store.dispatch('User/fetchRequest')
      this.$store.dispatch('User/fetchProfile')
      this.$store.dispatch('User/fetchEnfants')
      if (this.relationEnAttente.length === 1 || this.relationEnAttente.length === 0) {
        this.$store.commit('Notifications/setPendingRequestsDialog', false)
      }
      setTimeout(() => {
        this.$set(this.finishRel, rel.id, false)
      }, 1000)
    },
    setShowGroupe (rel) {
      this.showSuccess = false
      this.showSuccessRefuse = false
      this.activeRela = rel
      this.showGroupe = true
    }
  }
}
</script>

<template lang="pug">
  .demande-ajout
    v-dialog(v-model='pendingRequestsDialog', content-class='pending-requests-dialog custom-dialog', max-width='600')
      v-dialog(v-model='showGroupe', max-width='500',content-class='custom-dialog', persistent)
        v-card
          v-card-title.secondary.white--text.dialog-title Sélectionner un groupe
          v-card-text.relative
            div.pt3
              div.tc.f5.mb3.secondary--text Veuillez attribuer un groupe à l'élève.
              v-select(:items='allClasses', hide-details, v-model='selectClasse', placeholder='Choisir/créer un groupe', item-text='titre', item-value='id', outlined, solo, flat, color='secondary', item-color='secondary')
            div.pt4(v-if='selectClasse === "new"')
              div.tc.f5.mb3.secondary--text Veuillez donner un nom au nouveau groupe.
              v-text-field(placeholder='Ma nouvelle classe', hide-details, v-model='nameClasse', outlined, solo, flat, color='secondary')
            div.flex.flex-column.mt4
              v-btn(color='success', x-large, block, @click='acceptRelation', :disabled='!selectClasse || (selectClasse && selectClasse === "new" && !nameClasse)') Confirmer
              v-btn.mt3(color='error', text, @click='showGroupe = false') Annuler
      v-snackbar(color='error', bottom, v-model='error', :timeout='5000', fixed)
        span Une erreur est survenue
        font-awesome-icon.mr2.white--text(:icon="['fad', 'times-circle']", style='font-size: 24px')
      v-snackbar(color='success', bottom, v-model='showSuccess', :timeout='5000', fixed)
        span La demande à été acceptée
        fa.mr2.white--text(:icon="['fad', 'exclamation-circle']", style='font-size: 24px')
      v-card.relative
        v-hover(v-slot:default="{ hover }")
          v-btn.dialog-button(@click='pendingRequestsDialog = false', depressed, fab, x-large, color='secondary')
            font-awesome-icon.f3(:icon="['fas', 'times']")
        v-card-title.dialog-title.flex-column.primary.white--text
          h2 Demande d'ajout
        v-card-text()
          div.pa4(v-if='relationEnAttente.length === 0') Vous n'avez actuellement aucune demande d'ajout
          v-list(v-else, rounded)
            template(v-for='(rel, index) in relationEnAttente')
              v-list-item(@click, :key='index')
                v-list-item-content
                  v-list-item-subtitle
                    div {{ formatDate(rel.createdAt)}}
                  v-list-item-title.b {{rel.emetteur.info.prenom}} {{rel.emetteur.info.nom}}

                v-list-item-action
                  div.flex.justify-between(v-if='!stateLoad(rel.id)')
                    v-btn.mr2(@click='refuseRelation(rel, index)', color='error', icon)
                      font-awesome-icon.f2(:icon="['fad', 'times-circle']")
                    v-btn(@click='acceptRelation(rel, index)', color='success', icon)
                      font-awesome-icon.f2(:icon="['fad', 'check-circle']")
                  div.flex(v-else-if='loadRel[rel.id]')
                    v-progress-circular(indeterminate, color='success')
                  div(v-else)
                    font-awesome-icon.f2.success--text(:icon="['fad', 'check-circle']")
              v-divider(v-if='index < relationEnAttente.length - 1')
</template>

<style>

</style>
