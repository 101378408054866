<script>
// import axios from 'axios'
export default {
  name: 'userCodeConfirmation',
  data () {
    return {
      loading: false,
      finish: false,
      showError: false,
      code: null
    }
  },
  computed: {
    showCodeConfirmation: {
      set (val) { this.$store.commit('Dialogs/setCodeConfirmation', val) },
      get () { return this.$store.state.Dialogs.showCodeConfirmation }
    }
  },
  watch: {
    code (newVal) {
      if (!newVal || newVal === null || newVal === '') {
        this.showError = false
      }
    }
  },
  methods: {
    reset () {
      this.code = null
      this.loading = false
      this.showError = false
      this.finish = false
    },
    validateCode () {
      console.log('à faire')
    }
  }
}
</script>
<template lang="pug">
v-dialog(v-model='showCodeConfirmation', max-width='600',content-class='custom-dialog', persistent)
  v-dialog(v-model='loading', hide-overlay, persistent, width='400')
    v-card(color='primary', dark)
      v-card-text
        div.dialog-text.pa4.secondary--text
          div.b.f4.mb3.tc Validation en cours...
          v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)

  v-card.light(v-if='!loading && !finish')
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='showCodeConfirmation = !showCodeConfirmation', @click.native='reset', depressed, fab, x-large, color='light')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text Confirmation
    v-card-text.relative
      div.dialog-text
        div Veuillez entrer votre mot de passe pour continuer
    v-card-text.relative
      v-text-field.code-input(color='secondary', placeholder='* * * *', solo, hide-details, v-model='code', clearable, @keyup.enter='validateCode')
      .arrow-icon__wrapper
        font-awesome-icon.f1.arrow-icon.animated.bounce.delay-2s(:icon="['fas', 'arrow-alt-down']")
      v-alert.light.animated.fadeInDown(v-if='showError && code', dense, text, type='error', background-color='light') Le code entré est invalide.
    v-btn.secondary--text(@click='validateCode', color='primary', block, x-large, :disabled='!code')
      span.f4 Continuer
</template>
<style lang='sass'>

</style>
