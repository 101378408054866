<script>
import { debounce } from 'lodash-es'
import DetailSelection from '@/components/presentation/exercices/detailSelection'
import ExerciceInformation from '@/components/exercices/exerciceInformations.vue'
import BoutiqueSup from '@/mixins/boutiqueSup.js'
import { VueMathjax } from 'vue-mathjax'
export default {
  name: 'SelectExercices',
  components: {
    DetailSelection,
    ExerciceInformation,
    VueMathjax

  },
  mixins: [BoutiqueSup],
  watch: {
    '$route': async (newVal) => {
      await this.fetchRessources()
    },
    '$store.state.Dialogs.showExercicesSelection': {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.onDialogOpen()
          })
        }
      }
    },
    selectedExercices: {
      deep: true,
      handler (newVal) {
        const isClasseMaison = this.selectedClasseMaison?.length > 0

        this.$store.commit('App/updateTreeviewClasseMaison', this.treeviewClasseMaison.map(ex => {
          ex.unselectable = newVal?.length ? !isClasseMaison : false
          return ex
        }))
        this.$store.commit('App/updateTreeviewExercicesV2', this.treeviewExercicesV2.map(ex => {
          ex.unselectable = newVal?.length ? !newVal.find(e => e.parentslug === ex.slug) : false
          return ex
        }))

        this.updateShowTitles()
      }
    },
    treeviewClasseMaison () {
      this.updateShowTitles()
    },
    treeviewExercicesV2 () {
      this.updateShowTitles()
    }
  },
  data () {
    return {
      quantity: 1,
      open: [],
      openClasseMaison: [],
      openV2: [],
      lastOpen: [],
      allOpened: false,
      loading: false,
      loadinggroup: false,
      showError: false,
      searchExercices: null,
      debounceSearch: null,
      selectedClasseMaison: [],
      selectedV2: [],
      showOwn: true,
      showClasseMaison: true,
      showV2: true,
      infoId: null,
      infoSup: null,
      formula: null
    }
  },
  computed: {
    selectedExercices () {
      return [...this.selectedClasseMaison, ...this.selectedV2]
    },
    selectionFilter () {
      const tro = this.$refs.treeRefOwn?.nodes ? Object.values(this.$refs.treeRefOwn?.nodes) : []
      const trcm = this.$refs.treeRefClasseMaison?.nodes ? Object.values(this.$refs.treeRefClasseMaison?.nodes) : []
      const trv2 = this.$refs.treeRefV2?.nodes ? Object.values(this.$refs.treeRefV2?.nodes) : []
      const allNodes = [...tro, ...trcm, ...trv2]
      if (this.selectedExercices.length > 0 && allNodes.length) {
        const selected = allNodes.filter(e => e.isSelected)
        const idToExclude = []
        selected.forEach((element) => {
          if (element.children?.length > 0) {
            idToExclude.push(...element.children)
          }
        })
        const finalSelection = selected.filter(e => !idToExclude.includes(e.item.id))
        return finalSelection
      } else {
        return []
      }
    },
    treeviewExercices () {
      const p = this.$route.query?.produit
      const te = this.$store.state.App.treeviewExercices
      if (p) {
        const onlyPreselectedProduct = te.filter(exo => exo?.id === p)
        return onlyPreselectedProduct.length === 1 ? onlyPreselectedProduct : te
      }
      return te
    },
    treeviewClasseMaison () {
      return this.$store.state.App.treeviewClasseMaison
    },
    treeviewExercicesV2 () {
      return this.$store.state.App.treeviewExercicesV2
    },
    showExercicesSelection: {
      set (val) { this.$store.commit('Dialogs/setExercicesSelection', val) },
      get () { return this.$store.state.Dialogs.showExercicesSelection }
    },
    niveauOrdred () {
      const sortDesc = (a, b) => a - b
      return [...this.infoSup.niveau_ids].sort(sortDesc)
    }
  },
  methods: {
    async onDialogOpen () {
      await this.fetchRessources()
      this.checkValStore()
      this.openPreselectedItem()
    },
    async fetchRessources () {
      this.loadinggroup = true
      await this.$store.dispatch('App/fetchRessourcesExercices')
      this.loadinggroup = false
    },
    openPreselectedItem () {
      const { produit } = this.$route.query
      const preselectClasseMaison = this.treeviewClasseMaison.find(e => e.id === produit)
      this.openClasseMaison = preselectClasseMaison ? [preselectClasseMaison] : []
      const preselectV2 = this.treeviewExercicesV2.find(e => e.id === produit)
      this.openV2 = preselectV2 ? [preselectV2] : []
    },
    close () {
      this.showExercicesSelection = !this.showExercicesSelection
      this.reset()
    },
    reset () {
      this.selectedClasseMaison = []
      this.selectedV2 = []
      this.openClasseMaison = []
      this.openV2 = []
      this.loading = false
      this.loadinggroup = false
      this.showError = false
      this.finish = false
      this.quantity = 1
    },
    pushToList () {
      this.$store.commit('Dialogs/setExactSelectedExercices', this.selectedExercices)
      this.$store.commit('Dialogs/setExercicesSelection', false)
      this.selectedClasseMaison = []
      this.selectedV2 = []
    },
    checkValStore () {
      this.selectedClasseMaison = []
      this.selectedV2 = []
      this.$store.state.Dialogs.selectedExercices.forEach(ex => {
        if (ex.type === 'SuperNoeud') {
          this.selectedClasseMaison.push(ex)
        } else if (ex.type === 'BoutiqueItemContenu') {
          this.selectedV2.push(ex)
        }
      })
    },
    handleSearch: debounce(function handleSearch (val) {
      if (val) {
        if (!this.allOpened) {
          this.$refs.treeRefOwn.updateAll(true)
          this.$refs.treeRefClasseMaison.updateAll(true)
          this.$refs.treeRefV2.updateAll(true)
          this.allOpened = true
        }
      } else {
        this.$refs.treeRefOwn.updateAll(false)
        this.$refs.treeRefClasseMaison.updateAll(false)
        this.$refs.treeRefV2.updateAll(false)
        this.allOpened = false
      }
      this.debounceSearch = val
      this.updateShowTitles()
    }, 500, { trailing: true }),
    selectionExerciceCounter (selection) {
      if (this.selectionFilter?.length === 1 && selection?.children?.length) {
        return this.selectedExercices?.length
      }
      return selection?.children?.length ? selection.children.length : null
    },
    updateShowTitles () {
      // setTimeout is needed because we interact with the DOM, and we need to wait for Vue to render everything before checking $refs.nodes
      setTimeout(() => {
        this.showClasseMaison = this.treeviewClasseMaison?.length && !!Object.values(this.$refs.treeRefClasseMaison?.nodes ?? {}).filter(n => n.parent === null).filter(n => n.vnode !== null)?.length
        this.showV2 = this.treeviewExercicesV2?.length && !!Object.values(this.$refs.treeRefV2?.nodes ?? {}).filter(n => n.parent === null).filter(n => n.vnode !== null)?.length
      })
    },
    isMath (val) {
      if (val.includes('$$')) {
        console.log('val', val)
        return true
      }
      return false
    }
  }
}
</script>

<template lang='pug'>
v-dialog(v-if='showExercicesSelection', v-model='showExercicesSelection', max-width='800',content-class='custom-dialog', persistent, scrollable, :fullscreen="$store.state.App.windowSize.width < 700")
  v-dialog(v-model='loading', hide-overlay, persistent, width='400')
    v-card(color='primary', dark)
      v-card-text
        div.dialog-text.secondary--text
          div.b.f4.mb3.tc {{$t("messages.validation")}}
          v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)

  v-card.light()
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='close', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.primary.white--text {{$t("action.select-des-exercices")}}
    v-card-text.relative
      v-text-field.mt3(light, v-model='searchExercices', @input='handleSearch', :label='$t("exercices.trouver-exercices")', rounded, solo, clearable, clear-icon='mdi-close-circle-outline', hide-details)
      div.selection-wrapper.hide-checkbox(:class='{"exercices__class" : showOwn }')
        template(v-if='loadinggroup')
          div.dialog-text.secondary--text
            div.b.f4.mb3.tc {{$t("menu.signets-ex")}}...
            v-progress-circular.mt1(indeterminate, color='primary', height='10', rounded, striped)
            div.f6 {{$t('messages.chargement-exercices')}}
        section(v-else)
          v-card-text
            div.section-title(v-if="showClasseMaison") {{ $t('menu.exos-classe-maison') }}
            v-treeview(
              ref='treeRefClasseMaison'
              v-model='selectedClasseMaison'
              :items='treeviewClasseMaison'
              :search='debounceSearch'
              :open.sync="openClasseMaison"
              item-key='id'
              item-disabled='unselectable'
              rounded hoverable open-on-click return-object dense selectable light
              selected-color='primary'
            )
              template(v-slot:label='{ item }')
                div(v-if='item.sousTitre', v-html='item.sousTitre')
                div(v-else) {{item.name}}
            div.section-title(v-if="showV2") {{ $t('menu.exos-achetes') }}
            v-treeview(
              ref='treeRefV2'
              v-model='selectedV2'
              :items='treeviewExercicesV2'
              :search='debounceSearch'
              :open.sync="openV2"
              item-key='id'
              item-disabled='unselectable'
              rounded hoverable open-on-click return-object dense selectable light
              selected-color='primary'
            )
              template(v-slot:label='{ item }')
                div(v-if='item.sousTitre', v-html='item.sousTitre')
                div(v-else) {{item.name}}
            div.section-title(v-if="!showOwn && !showClasseMaison && !showV2") {{ $t('menu.no-exo-found') }}

        v-divider(:vertical='$store.state.App.windowSize.width > 700')
        section
          v-card-text(style='padding-right: 0px')
            div.section-title {{$t("exercices.exercices-select")}}
            v-scroll-y-transition(group, mode='out-in')
              span.secondary--text.i.f5.o-50(v-if='!selectedExercices || selectedExercices.length < 1', key='empty') {{$t("exercices.aucun-exercice-select")}}
              v-expansion-panels(key='hardcoddedkey' multiple)
                v-expansion-panel(
                  v-for='(selection, i) in selectionFilter'
                  :key="selection.item.id"
                  :disabled="selection.parent === 'classealamaison' || selection.parent === 'myquestions' || selection.parent === null"
                )
                  v-expansion-panel-header(@click='boutiqueItemSup(selection.item.id)')
                    v-list-item(:key='i', style='padding: 0;')
                      v-list-item-avatar(color='light')
                        font-awesome-icon.f4(:icon="['fad', (selection.children && selection.children.length > 0) ? 'folder' : 'file-alt']")
                      v-list-item-content
                        detail-selection(v-if='selection && selection.parent', :treeviewExercices='treeviewExercices', :selection='selection')
                        v-list-item-title(v-if='selection.item.sousTitre', v-html='selection.item.sousTitre', style='white-space: normal')
                        v-list-item-title(v-else, v-html='selection.item.name', style='white-space: normal')
                        v-list-item-subtitle(v-if="selectionExerciceCounter(selection) !== null") {{ $tc('exercices.x-exercice-multiple', selectionExerciceCounter(selection), { nb: selectionExerciceCounter(selection) }) }}
                  v-expansion-panel-content(v-if='infoSup !== null')
                    exercice-information(:infoSup='infoSup')
                  v-divider(v-if='i < selectionFilter.length - 1', :key='"divider-" + i')
    div.flex-grow-1
    v-btn(@click='pushToList()', color='primary', depressed, block, x-large, :disabled='!selectionFilter || selectionFilter.length === 0')
      span.f4 {{$t("action.select-exercices")}}
</template>
<style lang='sass'>
.exercices__class
  .v-card__text
    // .purchred__class
    .v-treeview-node__children
      .v-treeview-node--click
        margin-left: 60px
        padding: 8px 0px
        border-radius: 8px !important
        margin-top: 10px
        &:nth-child(odd)
          background-color: var(--v-light-darken1)
        &:nth-child(even)
          background-color: var(--v-light-darken3)
      .v-treeview-node__root
        align-items: start
        .v-treeview-node__level
          width: 0px
        .v-treeview-node__label
          margin-top: 3px
      .v-treeview-node__children
        padding-right: 5px
        .v-treeview-node--click
          &:nth-child(odd)
            background-color: var(--v-light-darken2)
          &:nth-child(even)
            background-color: var(--v-light-darken4)
  .hide-checkbox
    button + button.v-treeview-node__checkbox
      display: none !important
  .v-treeview
    .v-treeview-node
      .v-treeview-node__root
        // align-items: flex-start
        .v-treeview-node__content
          .v-treeview-node__label
            white-space: normal
            line-height: 1.15
  .v-list
    .v-list-item
      .v-list-item__content
        .v-list-item__subtitle
          font-weight: 200
          font-size: 12px
        .v-list-item__title
          font-size: 15px
  .dialog-text
    padding: 16px 2rem
</style>
