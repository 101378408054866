import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
// import { ApolloLink } from 'apollo-link'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import VueApollo from 'vue-apollo'
import schemaPlus from './schema.json'
import schema from './schemaV2.json'
import VueCookies from 'vue-cookies'

const fragmentMatcherPlus = new IntrospectionFragmentMatcher({
  schema: schemaPlus
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: { __schema: schema.__schema }
})

const middlewareLinkPlus = setContext((_, { headers }) => {
  let auth = null
  if (VueCookies.get('classParentToken')) {
    auth = 'Bearer ' + VueCookies.get('classParentToken')
  }
  return {
    headers: {
      ...headers,
      'Accept-Language': 'FR-QC',
      'X-Referer': window.location.href,
      Authorization: auth
    }
  }
})

const httpLinkPlus = new HttpLink({
  uri: 'https://api.dokoma.com/api/v1/dokoma/graphql'
})

const httpLinkV2 = new HttpLink({
  uri: 'https://api.dokoma.com/api/v1/graphql/salle_classe'
})

export const apolloClientPlus = new ApolloClient({
  link: middlewareLinkPlus.concat(httpLinkPlus),
  cache: new InMemoryCache({ fragmentMatcher: fragmentMatcherPlus })
})
const cache = new InMemoryCache({ fragmentMatcher })
export const apolloClientV2 = new ApolloClient({
  link: middlewareLinkPlus.concat(httpLinkV2),
  cache,
  connectToDevTools: true
})

// const cache = new InMemoryCache({ possibleTypes })
// const httpLink = createHttpLink({ uri });

// const client = new ApolloClient({
//   cache,
//   link: httpLink,
// })

Vue.use(VueApollo)

export default new VueApollo({
  clients: {
    plus: apolloClientPlus,
    v2: apolloClientV2
  },
  defaultClient: apolloClientV2
})
