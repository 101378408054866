import geolocation from '@/services/geolocation'
import axios from 'axios'

const state = {
  meteo: null,
  meteoLoading: false
}

const getters = {}

const actions = {
  async fetchMeteo ({ commit }) {
    commit('setMeteoLoading', true)

    try {
      let temp = await geolocation.getCurrentPosition()
      if (temp.lat && temp.lng) {
        await axios.get(`//api.openweathermap.org/data/2.5/weather?lang=fr&lat=${temp.lat}&lon=${temp.lng}&APPID=6f3bbbdb2090a753afaba52bf3182f83&units=metric`).then(({ data }) => {
          commit('setMeteo', data)
          commit('setMeteoLoading', false)
        })
      }
    } catch (e) {
      // console.error(e)
    }
  }
}

const mutations = {
  setMeteo (state, payload) {
    state.meteo = payload
  },
  setMeteoLoading (state, payload) {
    state.meteoLoading = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
