import { apolloClientV2 } from '@/setup/apollo.js'
import { groupBy } from 'lodash-es'
const state = {
  devoirs: [],
  devoirsCurrent: [],
  devoirsRecent: null,
  calendriers: [],
  periodeActive: null,
  showOnlyFavs: false,
  stats: {}
}

const actions = {
  async fetchDevoirsRecent ({ commit }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
        variables: {
          tri: [{ sujet: 'CREATION', direction: 'DESC' }],
          limit: 10,
          filtre: {
            metas: [{ type: 'serie' }, { type: 'exercice' }, { type: 'boutiqueItem' }, { contenu: { type: 'ITEM' } }, { contenu: { type: 'ARBRE' } }]
          },
          identifiant: true
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        commit('setDevoirsRecent', groupBy(data.calendrierCalendrierElements.resultat, 'identifiant'))
      })
    } catch (e) {
      console.error(e)
    }
  },
  async fetchDevoirsCurrent ({ commit }) {
    try {
      const dateForCal = new Date()
      dateForCal.setDate(dateForCal.getDate() + 7)
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
        variables: {
          filtre: {
            fin: dateForCal,
            metas: [{ type: 'serie' }, { type: 'exercice' }, { type: 'boutiqueItem' }, { contenu: { type: 'ITEM' } }, { contenu: { type: 'ARBRE' } }]
          },
          identifiant: true
          // batch: true
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        // TODO AJOUT DE PAGINATION
        commit('setDevoirsSemaineCourante', data.calendrierCalendrierElements.resultat)
      })
    } catch (e) {
      console.error(e)
    }
  },
  async fetchCalendriers ({ commit }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/searchCalendriers.gql'),
        variables: { limit: 100 }
      }).then(({ data }) => {
        commit('setCalendriers', data.searchCalendriers.resultat)
      })
    } catch (e) {
      console.error(e)
    }
  }
}

const mutations = {
  setPeriodeActive (state, payload) {
    state.periodeActive = payload
  },
  setDevoirsRecent (state, payload) {
    state.devoirsRecent = payload
  },
  addDevoirsRecent (state, payload) {
    let temp = {}
    Object.assign(temp, state.devoirsRecent, payload)
    state.devoirsRecent = temp
  },
  setDevoirsSemaineCourante (state, payload) {
    state.devoirsCurrent = payload
  },
  addDevoirsSemaineCourant (state, payload) {
    state.devoirsCurrent.push(...payload)
  },
  setCalendriers (state, payload) {
    state.calendriers = payload
  },
  resetCalendriers (state) {
    state.calendriers = []
  },
  setShowOnlyFavs (state, payload) {
    state.showOnlyFavs = payload
  },
  addStat (state, { stats, id }) {
    state.stats[id] = stats
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
