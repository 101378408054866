<script>
export default {
  name: 'AvatarChoice',
  props: {
    size: { required: false, type: Number, default: 150 }
  },
  computed: {
    userAvatar: {
      get () { return this.$store.state.User.userAvatar },
      set (val) { this.$store.commit('User/setUserAvatar', val) }
    },
    filteredAvatars () {
      if (this.avatars) {
        return this.avatars
      } else {
        return null
      }
    },
    cssProps () {
      return {
        '--avatarSize': this.size + 'px'
      }
    }
  },
  data () {
    return {
      loading: false,
      avatar: false,
      selectedAvatar: null,
      snackbar: false
    }
  },
  apollo: {
    avatars: {
      query: require('@/graphql/queries/v2/communImagesAvatars.gql'),
      update (data) {
        return data.communImagesAvatars
      },
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    async selectAvatar (avatar) {
      if (!avatar || (avatar && this.selectedAvatar !== avatar.id)) {
        this.snackbar = true
        if (avatar) {
          this.selectedAvatar = avatar.id
          this.userAvatar = avatar
        } else {
          this.selectedAvatar = null
          this.userAvatar = null
        }
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/v2/updateSalleClasseUsager.gql'),
            variables: {
              attrs: {
                role: 'SALLE_CLASSE_ENSEIGNANT',
                avatar: avatar ? avatar.id : ''
              }
            }
          }).then(({ data }) => {
            if (!data.updateSalleClasseUsager || data.updateSalleClasseUsager.errors) {
              // throw data ? data.updateSalleClasseUsager.errors : 'erreur lors de la mutation'
              this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: 'Erreur', description: "Erreur lors du changement de l'avatar." })
            } else {
              this.$store.dispatch('User/fetchMinProfile')
              this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: 'Succès', description: 'Avatar changé avec succès.' })
            }
            this.$store.commit('App/setShowSnackbarGlobal', true)
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}
</script>
<template lang="pug">
  div(:style='cssProps')
    template(v-if='loading')
      div Chargement des avatars
    template(v-else)
      .avatars__wrapper(:class='{"dark-mode" : $store.state.Preferences.darkMode}')
        v-lazy(:options="{ threshold: .75 }", v-for='(avatar, index) in filteredAvatars', :key='index')
          .avatar-card(:class='{"selected-avatar" : selectedAvatar === avatar.id}', @click='selectAvatar(avatar)')
            v-img(v-if='avatar && avatar.imageUrl', contain, :src='avatar.imageUrl')
            div(v-else)
              font-awesome-icon.f1(:icon="['fad', 'grin-tongue-squint']")
        v-lazy
          .avatar-card(:class='{"selected-avatar" : !selectedAvatar}', @click='selectAvatar()')
            v-img(v-if='avatar && avatar.imageUrl', contain, :src='avatar.imageUrl')
            div(v-else)
              div.initials(v-if='$store.state.User.profile && $store.state.User.profile.info && $store.state.User.profile.info.prenom') {{$store.state.User.profile.info.prenom[0]}}.{{$store.state.User.profile.info.nom[0]}}.
              font-awesome-icon.f1(v-else, :icon="['fas', 'times']")
</template>

<style lang='sass' scoped>
  .avatars__wrapper
    width: 100%
    margin: 0 auto
    padding: 40px 0
    display: grid
    // grid-auto-rows: 210px
    grid-template-columns: repeat(auto-fit, minmax(var(--avatarSize), 1fr))
    grid-gap: 30px
    justify-content: center
    align-content: space-between
    // border: solid 1px white
    &.dark-mode
      .avatar-card
        background-color: var(--v-light-base)
        border-color: var(--v-light-base)
    > div
      height: var(--avatarSize)
      align-self: start
      display: flex
      justify-content: center
      align-items: center
      padding-bottom: 10px
      .avatar-card
        background-color: white
        width: var(--avatarSize)
        height: var(--avatarSize)
        border-radius: 100px
        text-align: center
        position: relative
        overflow: hidden
        display: flex
        justify-content: center
        align-items: center
        overflow: hidden
        box-shadow: $shadow-sm
        border: solid 10px white
        transition: all 0.5s $custom-ease-in-out
        > div
          transition: all 0.5s $custom-ease-in-out
          transform: scale(0.75)
        .initials
          pointer-events: none
          font-size: 36px
          // font-family: $display-font
          // font-weight: $bold
          color: var(--v-secondary-base)
        &:hover
          // border: solid 3px black
          box-shadow: $shadow-xl
          > div
            transform: scale(1)
        &.selected-avatar
          border: solid 10px var(--v-primary-base) !important
    @media all and (max-width: $small)
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr))
      grid-gap: 30px
      > div
        height: 100px
        .avatar-card
          width: 80px
          height: 80px
</style>
