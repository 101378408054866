import VueCookies from 'vue-cookies'
import axios from 'axios'
import { apolloClientV2 } from '@/setup/apollo.js'

const state = {
  windowSize: {
    width: 0,
    height: 0
  },
  mainMenuVisibility: false,
  mobileTemplate: false,
  colorLibrary: {
    primary: [
      '#5465ff', '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5',
      '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A',
      '#ffb800', '#FF9800', '#FF5722', '#795548',
      '#9E9E9E', '#607D8B'
    ],
    accent: [
      '#c2ff7e', '#80ffdb', '#C575FF', '#ff206e', '#fb8b24', '#5CB8FF',
      '#FFAB91', '#ffc5c5', '#e2fdff', '#80DEEA', '#80CBC4', '#A5D6A7',
      '#C5E1A5', '#E6EE9C', '#FFF59D', '#FFE082', '#FFCC80',
      '#BCAAA4', '#EEEEEE', '#B0BEC5'
    ]
  },
  notificationsSidebar: false,
  lockedScroll: false,
  geoLoc: null,
  histo: [],
  newMess: false,
  currentRouteName: '',
  devoirActif: null,
  showSnackbarGlobal: false,
  snackbarGlobalContent: {
    title: 'Titre par défaut',
    description: 'description',
    timeout: 4000,
    type: 'success'
  },
  alreadyFetch: false,
  treeviewExercices: [], // Deprecated: was not able to split the differents categories
  treeviewClasseMaison: [],
  treeviewExercicesV2: [],
  listeGraph: []
}

const getters = {}

const actions = {
  fetchHisto ({ state }) {
    if (VueCookies.get('classe_histo_parent')) {
      state.histo = JSON.parse(VueCookies.get('classe_histo_parent'))
    }
  },
  async fetchRessourcesExercices ({ commit, state, rootState }) {
    if (state.listeGraph.length === 0 && !state.alreadyFetch && rootState.User.connected) {
      commit('setFetching', true)

      // Fetch Exercices V1
      try {
        await axios.get('https://api.dokoma.com/api/v1/cache/clients/dokoma_v1/fr/graphs/super_graphs/classe_a_la_maison.json').then(({ data }) => {
          commit('setListeGraph', data.super_graph_classealamaison)
          commit('setTreeviewExercices', data.super_graph_classealamaison)
          return true
        })
      } catch (e) {
        console.error('erreur fetching ressources', e)
        return false
      }

      // Fetch Exercices v2
      // IL VA FALLOIR DRILLER TOUT LES BOUTIQUENITEN POUR ALLER PRENDRE DU STOCK
      try {
        await apolloClientV2.query({
          query: require('@/graphql/queries/v2/searchNuageItems.gql'),
          variables: {
            filtre: {
              formats: ['config-boutique-types-de-formats-exercices-en-ligne'],
              statuts: ['ACTIF']
            }
          }
        }).then(({ data }) => {
          commit('addTreeviewExercices', data.searchNuageItems.resultat)
        })
      } catch (e) {
        console.error('erreur fetching ressource', e)
      }
    }
  }
}

const mutations = {
  setRouteName (state, payload) {
    state.currentRouteName = payload
  },
  addEntryToHisto (state, payload) {
    // if (state.histo.length === 0) {
    //   state.histo.push(payload)
    // } else {
    //   let temp = state.histo.findIndex(el => el.slug === payload.slug)
    //   if (temp >= 1) {
    //     state.histo.splice(temp, 1)
    //     state.histo.splice(0, 1, payload)
    //   } else {
    //     state.histo.splice(0, 1, payload)
    //   }
    // }
    console.log('histo svp')
    state.histo.push(payload)
    VueCookies.set('classe_histo_parent', JSON.stringify(state.histo))
    console.log('test', JSON.stringify(state.histo))
  },
  setDevoirActif (state, payload) {
    state.devoirActif = payload
  },
  setWindowSize (state, payload) {
    state.windowSize = payload
  },
  setMainMenuVisibility (state, payload) {
    state.mainMenuVisibility = payload
  },
  setGeoloc (state, payload) {
    state.geoLoc = payload
  },
  setMobileTemplate (state, payload) {
    state.mobileTemplate = payload
  },
  setNotificationsSidebar (state, payload) {
    state.notificationsSidebar = payload
  },
  setNewMess (state, payload) {
    state.newMess = payload
  },
  setLockedScroll (state, payload) {
    state.lockedScroll = payload
  },
  setShowSnackbarGlobal (state, payload) {
    state.showSnackbarGlobal = payload
  },
  setSnackbarGlobalContent (state, payload) {
    state.snackbarGlobalContent = payload
  },
  setFetching (state, payload) {
    state.alreadyFetch = payload
  },
  updateTreeviewOwnExercices (state, payload) {
    state.treeviewOwnExercices = payload
  },
  updateTreeviewClasseMaison (state, payload) {
    state.treeviewClasseMaison = payload
  },
  updateTreeviewExercicesV2 (state, payload) {
    state.treeviewExercicesV2 = payload
  },
  addTreeviewExercices (state, payload) {
    const newItems = payload
      .filter(e => e.boutiqueItem?.contenus?.length)
      .map((e) => {
        return {
          id: e.boutiqueItem.id,
          idtab: e.boutiqueItem.id,
          name: e.boutiqueItem.titre,
          type: 'BoutiqueItem',
          nuage: e.id,
          slug: e.boutiqueItem.slug,
          unselectable: false,
          inClasse: e.nuageItemsCreateurs.total,
          distribution: e.distribution,
          nuageItemsCreateursTotal: e.nuageItemsCreateurs?.total,
          children: e.boutiqueItem?.contenus?.map(contenu => {
            return {
              id: contenu.id,
              name: contenu.titre,
              sousTitre: contenu.noeud?.contenu?.titre,
              nuage: e.id,
              type: 'BoutiqueItemContenu',
              slug: contenu.slug,
              parent: e.boutiqueItem,
              parentType: 'BoutiqueItem',
              parentslug: e.boutiqueItem.slug,
              inClasse: e.nuageItemsCreateurs.total,
              distribution: e.distribution,
              nuageItemsCreateursTotal: e.nuageItemsCreateurs?.total,
              children: []
            }
          })
        }
      })
    state.treeviewExercices.push(...newItems)
    state.treeviewExercicesV2.push(...newItems)
  },
  setTreeviewExercices (state, payload) {
    const items = []
    items.push(...payload.map((e) => {
      if (!e.tuiles || e.tuiles.length === 0) {
        return {
          id: e.id,
          idtab: e.id,
          name: e.titre,
          type: 'Série',
          matieres: e.matieres,
          niveaux: e.niveaux,
          slug: e.slug,
          children: e.supers_noeuds.length > 0 ? e.supers_noeuds.map((n) => {
            let tagTargetBlank = false
            let tagRedirect = false
            if (n.graph && n.graph.tag_invisibles) {
              n.graph.tag_invisibles.forEach((tag) => {
                if (tag.id === '0f9f6c95-06c0-47b3-89bf-11dcc837746f') {
                  tagTargetBlank = true
                } else if (tag.id === '38b8bb6b-b987-4a7f-bee1-e93b0a7dd6f3') {
                  tagRedirect = true
                }
              })
            }
            if (!tagTargetBlank && !tagRedirect) {
              return { idtab: n.graph.id, id: n.id, name: n.titre, slug: e.slug, type: 'SuperNoeud', parentid: e.id, parent: e.titre, parentslug: e.slug, parentType: 'Série' }
            }
          }) : []
        }
      } else {
        //   // on a des collecions
        const temp = []
        e.tuiles.forEach((tuile) => {
          temp.push({
            id: tuile.id,
            idtab: tuile.id,
            name: tuile.titre + ' - ' + e.titre,
            type: 'Série',
            matieres: tuile.matieres,
            niveaux: tuile.niveaux,
            slug: tuile.slug,
            children: tuile.supers_noeuds.length > 0 ? tuile.supers_noeuds.map((n) => {
              let tagTargetBlank = false
              let tagRedirect = false
              if (n.graph && n.graph.tag_invisibles) {
                n.graph.tag_invisibles.forEach((tag) => {
                  if (tag.id === '0f9f6c95-06c0-47b3-89bf-11dcc837746f') {
                    tagTargetBlank = true
                  } else if (tag.id === '38b8bb6b-b987-4a7f-bee1-e93b0a7dd6f3') {
                    tagRedirect = true
                  }
                })
              }
              if (!tagTargetBlank && !tagRedirect) {
                return { idtab: n.graph ? n.graph.id : null, id: n.id, name: n.titre, slug: e.slug, type: 'SuperNoeud', parentid: tuile.id, parent: e.titre, parentslug: e.slug, parentType: 'Série' }
              } else {
                return null
              }
            }) : []
          })
        })
        return temp
      }
    }))
    let classealamaison = items.flat().filter(e => e && e.id)
    classealamaison.forEach((elem) => {
      if (elem.children.length > 0) {
        elem.children = elem.children.filter(e => e && e.id)
      }
    })
    classealamaison = classealamaison.filter(e => e.children.length > 0)
    const exercicesToAdd = { id: 'classealamaison', idtab: 'classealamaison', name: 'Les exercices de la classe à la maison', children: classealamaison, unselectable: false }
    state.treeviewExercices.push(exercicesToAdd)
    state.treeviewClasseMaison = [exercicesToAdd]
  },
  setListeGraph (state, payload) {
    state.listeGraph = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
