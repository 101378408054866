const state = {
  sideNav: false
}

const mutations = {
  setSideNav (state, payload) {
    state.sideNav = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
