const state = {
  currentGraph: null,
  nodeSelect: null,
  baseNodeSelect: {},
  nodePrecedent: null,
  navigation: []
}

const getters = {}

const actions = {
}

const mutations = {
  addNavigation (state, val) {
    state.navigation.push(val)
  },
  cleanNav (state, val) {
    state.navigation = []
  },
  removeNavigation (state, val) {
    const temp = state.navigation.findIndex(el => el.id === (val.id ? val.id : val))
    const elemNumberToRemove = state.navigation.length - temp
    if (elemNumberToRemove > 0) {
      state.navigation.splice(temp, state.navigation.length)
    } else {
      state.navigation.splice(temp, 1)
    }
  },
  setBaseNodeSelect (state, val) {
    state.baseNodeSelect = val
  },
  setNodeSelect (state, val) {
    state.nodeSelect = val
  },
  setCurrentGraph (state, val) {
    state.currentGraph = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
