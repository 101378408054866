import Vue from 'vue'
import './plugins/fontawesome'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import VuePageTransition from 'vue-page-transition'
import './plugins/vueperfectscrollbar'
import './plugins/vuedragscroll'
import './plugins/vueshepherd'
import './plugins/dokomaBlob'
import './plugins/dayjs'
import { i18n } from '@/setup/i18n.js'
import VTooltip from 'v-tooltip'

import apolloProvider from '@/setup/apollo'
import VueCookies from 'vue-cookies'
import VueMatomo from 'vue-matomo'
import moment from 'moment'

Vue.use(VueCookies)
Vue.use(VuePageTransition)
Vue.use(VTooltip)

Vue.use(require('vue-chartist'))

Vue.prototype.$moment = moment

Vue.config.productionTip = false

Vue.use(VueMatomo, {
  host: 'https://matomo.lichencommunications.com/',
  siteId: 15,
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  debug: false
})
Vue.config.productionTip = false

new Vue({
  store,
  apolloProvider,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
