<script>
import ManageEvent from '@/mixins/manageEvents.js'
export default {
  name: 'DeleteTableauBlanc',
  mixins: [ManageEvent],
  data () {
    return {
      showError: false,
      confirmWord: null,
      loading: false
    }
  },
  computed: {
    tableauBlancToEdit: {
      get () { return this.$store.state.Dialogs.tableauBlancToEdit },
      set (val) { this.$store.commit('Dialogs/setTableauBlancToEdit', val) }
    },
    showTableauBlancSuppression: {
      set (val) { this.$store.commit('Dialogs/setShowTableauBlancSuppression', val) },
      get () { return this.$store.state.Dialogs.showTableauBlancSuppression }
    }
  },
  methods: {
    reset () {
      this.groupToEdit = null
    },
    async deleteTableau () {
      // ON SUPPRIME LE DEVOIR/LE DOSSIER/LE TABLEAU
      this.showError = false
      this.loading = true
      let error = false
      const isCalendrierElementDestroyed = await this.destroyCalendrierElement(this.tableauBlancToEdit.id)
      if (isCalendrierElementDestroyed) {
        const isDossierDetroyed = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/destroySalleClasseEnregistrementDossier.gql'),
          variables: {
            id: this.tableauBlancToEdit.dossier
          }
        }).then(({ data }) => {
          if (!data.destroySalleClasseEnregistrementDossier || data.destroySalleClasseEnregistrementDossier.errors) {
            return false
          } else {
            return true
          }
        }).catch((e) => {
          return false
        })
        if (!isDossierDetroyed) {
          error = true
          console.error('Failed to destroy Dossier with id:', this.tableauBlancToEdit.dossier)
        }
      } else {
        error = true
        console.error('Failed to destroy CalendrierElement with id:', this.tableauBlancToEdit.dossier)
      }
      if (error) {
        this.loading = false
        this.$store.commit('App/setShowSnackbarGlobal', true)
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: 'erreur', description: 'Erreur lors de la suppression du tableau blanc.' })
      } else {
        this.loading = false
        this.$store.commit('App/setShowSnackbarGlobal', true)
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: 'Erreur', description: 'Le tableau blanc a bien été supprimé.' })
        this.showTableauBlancSuppression = false
        if (this.$route.name.includes('tableauDetail')) {
          this.$router.push({ name: 'tableau' })
        }
      }
    }
  }
}
</script>

<template lang="pug">
  v-dialog(v-if='showTableauBlancSuppression', v-model='showTableauBlancSuppression', max-width='600', content-class='custom-dialog', persistent)
    v-card.light
      v-hover(v-slot:default="{ hover }")
        v-btn.dialog-button(@click='showTableauBlancSuppression = !showTableauBlancSuppression', @click.native='reset', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
          font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title.secondary.white--text Supprimer le tableau blanc
      v-card-text.relative
        div.dialog-text()
          div
            span Pour confirmer la suppression du tableau
            br
            span.b {{tableauBlancToEdit.titre}}
            br
            span veuillez entrer le mot «EFFACER» dans le champ texte ci-dessous.
      v-card-text.relative
        .dialog-text
          v-text-field.code-input(light, color='secondary', placeholder='EFFACER', solo, hide-details, v-model='confirmWord', clearable, rounded)
      v-btn(@click='deleteTableau', color='primary', depressed, block, x-large, :disabled='(confirmWord !== "EFFACER" && confirmWord !== "ERASE") || loading')
        span.f4 Supprimer
</template>

<style>

</style>
