import { render, staticRenderFns } from "./navigationSidebar.vue?vue&type=template&id=4f509bbc&scoped=true&lang=pug&"
import script from "./navigationSidebar.vue?vue&type=script&lang=js&"
export * from "./navigationSidebar.vue?vue&type=script&lang=js&"
import style0 from "./navigationSidebar.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./navigationSidebar.vue?vue&type=style&index=1&id=4f509bbc&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f509bbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBadge,VBtn,VChip,VDivider,VList,VListGroup,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer})
