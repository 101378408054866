import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/es5/locale/fr'
// import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  // customVariables: ['~/assets/main.scss'],
  // treeShake: true,
  theme: {
    options: {
      customProperties: true
    },
    breakpoint: {
      thresholds: {
        xs: 340,
        sm: 600,
        md: 900,
        lg: 1200
      }
    },
    themes: {
      light: {
        dark: '#361824',
        light: '#ffe2ce',
        primary: '#ff284f',
        secondary: '#361824',
        // accent: '#7539ea',
        accent: '#ffe2ce',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        dark: '#2a0e1a',
        light: '#d59393',
        primary: '#ff284f',
        secondary: '#2a0e1a',
        accent: '#4a313b',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  lang: {
    locales: { fr },
    current: 'fr'
  }
})
