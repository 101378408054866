<script>
import { gsap, TimelineLite } from 'gsap'
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin.js'
gsap.registerPlugin(MorphSVGPlugin)
export default {
  data () {
    return {
      filterTarget: null,
      filterTargetTimeline: null,
      newValLength: null,
      oldValLength: null,
      color: [
        '#fda084', '#fda084', '#fda084', '#fda084'
      ]
    }
  },
  watch: {
    // plus logique de le faire sur le nom de la route que sur le params
    // Nous abons seulement 1 route avec un paramétre
    '$route.name': {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.newValLength = Object.keys(newVal).length
        }
        if (oldVal) {
          this.oldValLength = Object.keys(oldVal).length
        }
        this.triggerMorph(this.newValLength)
      }
    }
  },
  mounted () {
    const thus = this
    this.$nextTick(() => {
      thus.triggerMorph(Object.keys(thus.$route.params).length)
    })
  },
  methods: {
    triggerMorph (value) {
      if (window.document.getElementById('blob-' + value)) {
        const tl = new TimelineLite({})
        tl.to('#blob-0', {
          fill: this.$vuetify.theme.currentTheme.accent,
          morphSVG: {
            shape: '#blob-' + value,
            shapeIndex: 1
          },
          duration: 30,
          yoyo: true,
          repeat: -1,
          ease: 'power1.inOut'
        })
      }
    }
  }
}
</script>

<template lang="pug">
  .background-texture
    div.svg-wrapper
      <svg id='base-blob' viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <g transform="translate(300,300)">
          <path id='blob-0' d="M87.7,-117.7C110.7,-85.2,124.3,-54.8,139.3,-17.5C154.3,19.8,170.7,63.9,157.4,94.7C144.2,125.6,101.3,143.1,61.6,148.6C21.9,154.1,-14.7,147.5,-60.3,141.4C-105.8,135.3,-160.3,129.7,-199.5,97.7C-238.7,65.8,-262.6,7.6,-239.4,-28C-216.1,-63.6,-145.7,-76.6,-97.9,-104.7C-50.1,-132.8,-25.1,-175.9,3.6,-180.2C32.4,-184.6,64.7,-150.1,87.7,-117.7Z"/>
          <path class='no-visibility' id='blob-1' d="M159.9,-177.5C192,-163.5,192.5,-98.8,182.3,-48.2C172,2.3,150.9,38.7,126.1,64.5C101.3,90.4,72.7,105.8,44.5,111.5C16.2,117.1,-11.7,113.1,-49,110.1C-86.2,107.2,-132.7,105.3,-163.4,80.6C-194,55.9,-208.8,8.2,-195.6,-28.9C-182.5,-66.1,-141.4,-92.8,-104.1,-105.9C-66.8,-119,-33.4,-118.5,15.2,-136.6C63.9,-154.8,127.7,-191.5,159.9,-177.5Z"/>
          <path class='no-visibility' id='blob-2' d="M136.4,-176.3C152,-149.5,122.8,-83,120.4,-30.9C118.1,21.2,142.6,58.8,138.4,92.8C134.1,126.7,101.1,156.8,67.9,155.3C34.8,153.8,1.5,120.6,-25.7,98.6C-53,76.6,-74.1,65.8,-107.4,44.4C-140.7,23.1,-186,-8.9,-182.6,-32.8C-179.1,-56.8,-126.9,-72.8,-88.3,-95.5C-49.7,-118.2,-24.9,-147.6,17.8,-168.8C60.4,-190,120.8,-203,136.4,-176.3Z"/>
          <path class='no-visibility' id='blob-3' d="M87.7,-117.7C110.7,-85.2,124.3,-54.8,139.3,-17.5C154.3,19.8,170.7,63.9,157.4,94.7C144.2,125.6,101.3,143.1,61.6,148.6C21.9,154.1,-14.7,147.5,-60.3,141.4C-105.8,135.3,-160.3,129.7,-199.5,97.7C-238.7,65.8,-262.6,7.6,-239.4,-28C-216.1,-63.6,-145.7,-76.6,-97.9,-104.7C-50.1,-132.8,-25.1,-175.9,3.6,-180.2C32.4,-184.6,64.7,-150.1,87.7,-117.7Z"/>
        </g>
      </svg>
</template>

<style lang='sass'>
.no-visibility
  visibility: hidden

.background-texture
  opacity: 1
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  pointer-events: none
  transform: rotate(180deg)
  opacity: 0.5
  .svg-wrapper
    width: 100%
    height: 0
    padding-top: 100%
    position: relative
    svg
      position: absolute
      bottom: 20%
      left: -100%
      width: 250%
      height: 220%
      g
        path
          fill: var(--v-accent-darken3) !important
  @media all and (max-width: $medium)
    .svg-wrapper
      svg
        bottom: -50%
        left: -40%
        width: 158%
        height: 267%

</style>
