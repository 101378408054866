<script>
import ChatControls from '@/mixins/chatControls.js'

export default {
  name: 'NavigationBottom',
  mixins: [ChatControls],
  computed: {
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    }
  }
}
</script>

<template lang='pug'>
v-bottom-navigation.bottom-nav.animated.fadeInUp(app, grow, dark, background-color='secondary')
  v-btn
    router-link.menu-link.no-underline(to='/', exact, exact-active-class='active-link')
      font-awesome-icon.f4(:icon="['fas', 'home-lg']")
      div.mt1 Accueil
  v-btn
    router-link.menu-link.no-underline(to='/bibliotheque', exact, exact-active-class='active-link')
      font-awesome-icon.f4(:icon="['fas', 'books']")
      div.mt1 Bibliotheque
  v-btn.avatar-button(icon)
    router-link.no-underline(to='/profil')
      v-avatar(color='light', light)
        img.pa1(v-if='$store.state.User.profile &&  $store.state.User.profile.avatar', :src='$store.state.User.profile.avatar.imageUrl')
        div.f5(v-else-if='$store.state.User.profile && $store.state.User.profile && $store.state.User.profile.info') {{$store.state.User.profile.info.prenom[0]}}.{{$store.state.User.profile.info.nom[0]}}.
        font-awesome-icon.f4.secondary--text(v-else, :icon="['fas', 'user']")
  v-btn()
    router-link.menu-link.no-underline(to='/enfants', exact, exact-active-class='active-link')
      font-awesome-icon.f4(:icon="['fas', 'backpack']")
      div.mt1 Mes enfants
  v-menu(top, offset-y, min-width='200', content-class='extra-menu')
    template(v-slot:activator='{ on }')
      v-btn(v-on='on', color='primary', dark , icon)
        div.menu-link.no-underline
          font-awesome-icon.f4(:icon="['fas', 'ellipsis-v']")

    v-list
      v-list-item(@click='goChat()')
        v-list-item-icon
          font-awesome-icon(:icon="['fad', 'comment-smile']")
        v-list-item-content
          v-list-item-title Clavardage

      v-list-item(to='/calendrier')
        v-list-item-icon
          font-awesome-icon(:icon="['fad', 'calendar-alt']")
        v-list-item-content
          v-list-item-title Calendrier

      v-list-item(@click, disabled)
        v-list-item-icon
          font-awesome-icon(:icon="['fad', 'users-medical']")
        v-list-item-content
          v-list-item-title Créer un compte enfant

      v-list-item(href='https://boutique.dokoma.com')
        v-list-item-icon
          font-awesome-icon(:icon="['fad', 'shopping-cart']")
        v-list-item-content
          v-list-item-title Boutique Dokoma
  //- v-btn
    .menu-link.no-underline()
      font-awesome-icon.f4(:icon="['far', 'plus']")
      //- div.mt1 Autres options ici
</template>

<style lang='sass'>

</style>
