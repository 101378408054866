<script>
import AvatarChoices from '@/components/shared/avatarChoices.vue'
import NightMode from '@/components/home/options/nightMode.vue'

import VSwatches from 'vue-swatches'

export default {
  name: 'homeTutorial',
  components: {
    VSwatches,
    AvatarChoices,
    NightMode
  },
  data () {
    return {
      setupFinished: false,
      tutorialSteps: 1,
      steps: 3,
      imageError: false,
      themeColorMenu: false,
      accentColorMenu: false,
      hideTutorials: this.$store.state.Preferences.tutorials.home,
      hideAllTutorials: false
    }
  },
  watch: {
    themeColorMenu (newVal) {
      if (newVal) {
        this.accentColorMenu = false
      }
    },
    accentColorMenu (newVal) {
      if (newVal) {
        this.themeColorMenu = false
      }
    },
    hideTutorials (newVal) {
      this.finishTuto(this.hideTutorials)
    },
    hideAllTutorials () {
      this.toggleAllTuto(this.hideAllTutorials)
    }
  },
  computed: {
    showTutorialDialog: {
      set (val) { this.$store.commit('Dialogs/setShowTutorialDialog', val) },
      get () { return this.$store.state.Dialogs.showTutorialDialog }
    },
    // actionsRapidesListe: {
    //   set (val) { this.$store.commit('Home/setActionsRapidesListe', val) },
    //   get () { return this.$store.state.Home.actionsRapidesListe }
    // },
    // activeActionsRapides: {
    //   get () { return this.$store.state.Preferences.activeActionsRapides },
    //   set (val) {
    //     this.$store.dispatch('Preferences/setPreferences', { name: 'activeActionsRapides', data: val })
    //   }
    // },
    progressStyle () {
      return `width: ${((this.tutorialSteps - 2) / (this.steps - 1)) * 100}%`
    },
    darkMode () {
      return this.$store.state.Preferences.darkMode
    },
    themeColor: {
      get () { return this.$store.state.Preferences.themeColor },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'themeColor', data: val })
      }
    },
    themeColorAccent: {
      get () { return this.$store.state.Preferences.themeColorAccent },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'themeColorAccent', data: val })
      }
    },
    themeColorDark: {
      get () { return this.$store.state.Preferences.themeColorDark },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'themeColorDark', data: val })
      }
    }
  },
  methods: {
    resetTutorial () {
      this.tutorialSteps = 1
    },
    completeSetup () {
      this.setupFinished = true
      this.resetTutorial()
      this.$emit('updateTuto', 'home')
    },
    launchTour () {
      this.$emit('startTour')
      this.showTutorialDialog = false
      this.finishTuto()
    },
    validateImage (event) {
      this.imageError = true
    },
    finishTuto () {
      this.$emit('updateTuto', 'home')
    },
    toggleAllTuto (val) {
      // console.log('toggletutos', val)
      this.$emit('toggleAllTuto', val)
    }
  }
}
</script>
<template lang='pug'>
  div
    v-sheet.step-introduction(:color='$store.state.Preferences.darkMode ? "light" : "white"', light)
      template(v-if='tutorialSteps === 1')
        h1.step-title Bienvenue dans votre espace enseignant.
        div.step-description
          p Cet espace vous permettra d'avoir un contrôle total sur votre facon d'enseigner. Nous vous proposons une visite guidée pour vous familiariser avec vos nouveaux outils.
          p.i.o-50() Vous pourrez ajuster ces informations plus tard, au besoin.

      template(v-else-if='tutorialSteps === 2')
        h1.step-title Mode d'affichage sombre
        div.step-description
          p Texte à venir.
        div.mt3.swatch-wrapper
          night-mode(:fullButton='true')

      template(v-else-if='tutorialSteps === 3')
        h1.step-title Personnaliser mon espace
        div.step-description
          p Cet espace vous permettra d'avoir un contrôle total sur votre facon d'enseigner. Nous vous proposons une visite guidée pour vous familiariser avec vos nouveaux outils.
        div.mt3.swatch-wrapper
          template(v-if='!$store.state.Preferences.darkMode')
            v-menu(v-model='accentColorMenu', :close-on-content-click='false', offset-y, bottom, v-if='!$store.state.Preferences.darkMode')
              template(v-slot:activator='{ on }')
                v-btn.mr2(large ,depressed, v-on='on', color='secondary', outlined, rounded, style='padding-left: 14px')
                  font-awesome-icon.mr2.fa-2x(:icon="['fas', 'circle']", :style='{color: "var(--v-accent-base)"}')
                  span Couleur principale
              v-card.pa3(max-width='300')
                v-swatches(v-model='themeColorAccent', :swatches='$store.state.App.colorLibrary.accent', inline, shapes='circles')
            v-menu(v-model='themeColorMenu', :close-on-content-click='false', offset-y, bottom)
              template(v-slot:activator='{ on }')
                v-btn.mr2(large ,depressed, v-on='on', color='secondary', outlined, rounded, style='padding-left: 14px')
                  font-awesome-icon.mr2.fa-2x(:icon="['fas', 'circle']", :style='{color: "var(--v-primary-base)"}')
                  span Couleur d'accent
              v-card.pa3(max-width='300')
                v-swatches(v-model='themeColor', :swatches='$store.state.App.colorLibrary.primary', inline, shapes='circles')
          template(v-else)
            v-menu(v-model='themeColorMenu', :close-on-content-click='false', offset-y, bottom)
              template(v-slot:activator='{ on }')
                v-btn.mr2(large ,depressed, v-on='on', color='secondary', outlined, rounded, style='padding-left: 14px')
                  font-awesome-icon.mr2.fa-2x(:icon="['fas', 'circle']", :style='{color: "var(--v-primary-base)"}')
                  span Couleur d'accent
              v-card.pa3(max-width='300')
                v-swatches(v-model='themeColorDark', :swatches='$store.state.App.colorLibrary.primary', inline, shapes='circles')

      template(v-else-if='tutorialSteps === 4')
        h1.step-title Choisir un avatar
        div.step-description
          p Vos élèves pourront vous repérer rapidement avec votre avatar.
        v-list-item.no-underline.user-preview(v-if='$store.state.User.profile', two-line, style='text-align: left;')
          v-list-item-avatar.dark--text(color='white')
            img(v-if='$store.state.User.profile && $store.state.User.profile.avatar && !imageError', :src='$store.state.User.profile.avatar.imageUrl', @error='validateImage')
            span.f6(v-else) {{$store.state.User.profile.info.prenom[0]}}.{{$store.state.User.profile.info.nom[0]}}.
          v-list-item-content
            v-list-item-title(v-if='$store.state.User.profile.info', style='font-size: 18px') {{$store.state.User.profile.info.prenom}} {{$store.state.User.profile.info.nom}}
            div.f6(v-if='$store.state.User.profile.info') @{{$store.state.User.profile.info.username}}
      template(v-else-if='tutorialSteps === 5')
        h1.step-title Actions rapides
        div.step-description
          p Sélectionnez les actions que vous risquez d'effectuer souvent pour qu'elles soient disponible rapidement sur votre page d'accueil.
    .progress-container.animated.fadeIn(v-if='tutorialSteps > 1')
      .progress(:style='progressStyle')
      .circle(v-for='step in steps', :key='step', :class='{ active: step <= tutorialSteps - 1, finished: step < tutorialSteps - 1 }')
        span(v-if='step < tutorialSteps - 1')
          font-awesome-icon.fa-lg.white--text(:icon="['fas', 'check']")
        span(v-else) {{ step }}

    v-window(v-model='tutorialSteps')
      v-window-item(:value='1')
        div.step-content
          v-list.intro-list(light)
            v-list-item(@click='tutorialSteps++')
              v-list-item-avatar(:color='setupFinished ? "success" : "primary"', size='50')
                font-awesome-icon.fa-2x.white--text(v-if='setupFinished', :icon="['fas', 'check']")
                font-awesome-icon.fa-2x(v-else, :icon="['fad', 'palette']")
              v-list-item-content
                v-list-item-title Personnaliser mon espace
            v-list-item(@click='launchTour()')
              v-list-item-avatar(color='primary', size='50')
                font-awesome-icon.fa-2x(:icon="['fad', 'binoculars']")
              v-list-item-content
                v-list-item-title Lancer la visite guidée
          div.flex.items-center.justify-start.flex-column
            v-checkbox(v-model='hideTutorials', label='Masquer ce tutoriel', color='primary', hide-details)
            v-checkbox(v-model='hideAllTutorials', label='Masquer tous les tutoriels', color='primary', hide-details)

      v-window-item(:value='2')
        div.step-content()
          v-container.skeleton-content
            v-row
              v-skeleton-loader.mb2(type='toolbar', boilerplate)
              v-col(cols='6', md='6')
                v-skeleton-loader.dark-background(type='actions', boilerplate)
                v-skeleton-loader(type='card-avatar, article, actions', boilerplate)
                v-skeleton-loader(type='date-picker', boilerplate)
              v-col(cols='6', md='6')
                v-skeleton-loader(type='article, actions', boilerplate)
                v-skeleton-loader(type='table-heading, list-item-two-line, image, table-tfoot', boilerplate)
      v-window-item(:value='3')
        div.step-content()
          v-container.skeleton-content
            v-row
              v-skeleton-loader.mb2(type='toolbar', boilerplate)
              v-col(cols='6', md='6')
                v-skeleton-loader.dark-background(type='actions', boilerplate)
                v-skeleton-loader(type='card-avatar, article, actions', boilerplate)
                v-skeleton-loader(type='date-picker', boilerplate)
              v-col(cols='6', md='6')
                v-skeleton-loader(type='article, actions', boilerplate)
                v-skeleton-loader(type='table-heading, list-item-two-line, image, table-tfoot', boilerplate)
      v-window-item(:value='4')
        div.step-content(style='overflow-y: auto;')
          avatar-choices(:size='120')
      //- v-window-item(:value='5')
        div.step-content
          .chips-wrapper()
            v-chip-group(v-model='activeActionsRapides', column, multiple)
              template(v-for='(action, index) in actionsRapidesListe')
                v-chip(large, filter, color='white', active-class='active-chip', light)
                 span {{action.secondaryTitle}}
                 font-awesome-icon.ml3.mr2(:icon="['fad', 'info-circle']", v-tooltip.bottom="{ content: action.description, offset:'10px', classes: 'small-tooltip'}")
    div.step-actions(v-if='tutorialSteps !== 1')
      v-btn(@click='resetTutorial()', color='primary', large, rounded, outlined)
        span Passer
      div.flex-grow-1
      //- v-btn(@click='clearTuto', color='primary', large, rounded, outlined)
        span ClearTuto
      //- v-btn(@click='finishTuto', color='primary', large, rounded, outlined)
        span TestToggleTuto
      v-btn(@click='tutorialSteps--', color='primary', large, rounded, outlined)
        span Retour
      v-btn(v-if='tutorialSteps <= 3', @click='tutorialSteps++', color='primary', large, rounded)
        span Continuer
      v-btn(v-else, @click='completeSetup()', color='primary', large, rounded)
        span Compléter
</template>
<style lang='sass'>
  .vue-swatches__container
    background-color: transparent !important
</style>
