<script>
import ChatControls from '@/mixins/chatControls.js'
import LogUser from '@/mixins/login.js'

export default {
  name: 'LayoutHeader',
  mixins: [ChatControls, LogUser],
  components: {
  },
  props: {
    // words: { type: Array, required: true },
  },
  data () {
    return {
    }
  },
  apollo: {
    // searchUsersBy: {
    //   query: require('@/graphql/queries/compte/searchUsersBy.gql'),
    //   client: 'compte',
    //   variables () {
    //     return {
    //       attrs: {
    //         username: this.$store.state.User.profile.username
    //       }
    //     }
    //   },
    //   update (data) {
    //     if (data.search_users_by.length > 0) {
    //       this.$store.commit('User/setProfileDokoma', data.search_users_by[0])
    //       return data.search_users_by[0]
    //     } else {
    //       this.$store.commit('User/setCreateAccountDokoma', true)
    //     }
    //   }
    // }
  },
  computed: {
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    },
    notificationsSidebar: {
      get () { return this.$store.state.App.notificationsSidebar },
      set (val) { this.$store.commit('App/setNotificationsSidebar', val) }
    },
    mainMenuVisibility: {
      get () { return this.$store.state.App.mainMenuVisibility },
      set (val) { this.$store.commit('App/setMainMenuVisibility', val) }
    }
  },
  beforeDestroy () {
  },
  mounted () {
  },
  methods: {
    toggleMainMenu () {
      this.mainMenuVisibility = !this.mainMenuVisibility
    }
    // goChat () {
    //   window.open('https://blahblah.dokoma.com/clavardage/#/?dokUANE=' + this.$cookies.get('ClassParent').username + '&access=' + this.$cookies.get('ClassParent').access_code, '_blank')
    //   // return temp + '?dokUANE=' + this.$cookies.get('ClassParent').username + '&access=' + this.$cookies.get('ClassParent').access_code
    // }
  }
}

</script>

<template lang='pug'>
v-app-bar.navigation-header(app, :inverted-scroll='$store.state.App.currentRouteName && $store.state.App.currentRouteName.includes("home")', :hide-on-scroll='$store.state.App.currentRouteName && !$store.state.App.currentRouteName.includes("home")',  height='100', scroll-threshold='200')
  //- pre {{$store.state.App.currentRouteName}}
  v-list-item.user__wrapper(style='flex:0; padding:0px; width:220px;', to='/profil')
    v-avatar.light.mr3(size='50',v-if='$store.state.User.profile && $store.state.User.profile')
      img(v-if='$store.state.User.profile && $store.state.User.profile.avatar', :src='$store.state.User.profile.avatar.imageUrl')
      div.f3(v-else-if='$store.state.User.profile && $store.state.User.profile.user && $store.state.User.profile.user.prenom') {{$store.state.User.profile.user.prenom[0]}}.{{$store.state.User.profile.user.nom[0]}}.
      img(v-else, src='~@/assets/avatars/avatar_afro.png')
    v-list-item-content.user-content.secondary--text(v-if='$store.state.User.profile')
      v-list-item-title.user-title(v-if='$store.state.User.profile.info && $store.state.User.profile.info.prenom && $store.state.User.profile.info.nom') {{$store.state.User.profile.info.prenom}} {{$store.state.User.profile.info.nom}}
      v-list-item-subtitle(v-if='$store.state.User.profile.info.username') #[span.o-50(style='user-select:none;') @]{{$store.state.User.profile.info.username}}
  div.menu-links__wrapper
    template(v-if='$store.state.App.windowSize.width > 1200')
      router-link.menu-link.no-underline.dark--text(to='/', exact, exact-active-class='active-link')
        font-awesome-icon.f3.dark--text(:icon="['fad', 'home-lg']")
      router-link.menu-link.no-underline.dark--text(to='/bibliotheque', exact, exact-active-class='active-link')
        span Bibliothèque
      router-link.menu-link.no-underline.dark--text(to='/resultats', exact, exact-active-class='active-link')
        span Résultats
      router-link.menu-link.no-underline.dark--text(to='/exercices', exact, exact-active-class='active-link')
        span Exercices
  div.secondary-links__wrapper(style='width:220px')
    v-badge.vuetify-badge(v-model='$store.state.App.newMess', color='red', icon='mdi-exclamation-thick', overlap, offset-x='16', offset-y='16')
      v-hover(v-slot:default='{ hover }')
        v-btn.ml2(@click='goChat', icon, color='light', :class='{"white" : $store.state.App.newMess }')
          font-awesome-icon.f3.dark--text(:icon="['fad', 'comment-smile']", :class="{ 'animated jello infinite primary--text darken-3': hover || $store.state.App.newMess }")
        //- v-btn.ml2(to='/clavardage', icon, color='light', target='_blank', :class='{"white" : $store.state.App.newMess }')
          font-awesome-icon.f3.dark--text(:icon="['fad', 'comment-smile']", :class="{ 'animated jello infinite primary--text darken-3': hover || $store.state.App.newMess }")
    //- v-hover(v-slot:default='{ hover }')
      v-btn.ml2(@click='notificationsSidebar = !notificationsSidebar', icon, color='light')
        font-awesome-icon.f3.dark--text(:icon="['fad', 'bell']", :class="{ 'animated swing infinite deep-orange--text darken-3': hover }")
    //- v-hover(v-slot:default='{ hover }')
      v-btn.ml2(@click='logout', icon, color='light')
        font-awesome-icon.f3.dark--text(:icon="['fad', 'power-off']", :class="{ 'animated flash infinite red--text': hover }")
  v-btn.ml2(text, icon, large, color='light',v-if='$store.state.App.windowSize.width < 1201', @click='toggleMainMenu')
    v-hover(v-slot:default='{ hover }')
      font-awesome-icon.f3.dark--text(:icon="['fas', 'bars']", :class="{ 'animated jello infinite primary--text darken-3': hover }")
  //- router-link.menu-link.no-underline.dark--text(to='/ressources', exact, exact-active-class='active-link')
    span Ressources
</template>

<style lang='sass'>

</style>
