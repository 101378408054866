export default {
  data () {
    return {
    }
  },
  computed: {
    getCurrentTourSteps () {
      let steps = null
      if (this.$route && this.$route.name === 'home') {
        steps = [
          {
            id: 'etape-0',
            title: 'Bienvenue',
            text: 'Découvrez les fonctionnalités qui vous sont disponibles',
            buttons: 'default'
          },
          {
            id: 'etape-1',
            attachTo: { element: '#s-step-1', on: 'auto' },
            title: 'Notifications & clavardage',
            text: 'Le clavardage et vos notifications sont toujours disponible à partir de la barre du haut.',
            buttons: 'default'
          },
          {
            id: 'etape-2',
            attachTo: { element: '#s-step-2', on: 'auto' },
            title: 'Thème du site',
            text: "Vous pouvez personnaliser l'apparence du site avec les boutons suivant.",
            buttons: 'default'
          },
          {
            id: 'etape-3',
            attachTo: { element: '#s-step-3', on: 'auto' },
            title: 'Calendrier',
            text: 'Vous trouverez ici un aperçu de votre calendrier, et les événements à venir',
            buttons: 'default'
          },
          {
            id: 'etape-4',
            attachTo: { element: '#s-step-5', on: 'auto' },
            title: 'Centre de contrôle',
            text: "Votre page d'accueil vous permet d'avoir un aperçu rapide sur votre activité et celle de vos élèves.",
            buttons: 'exit'
          }
        ]
      }
      return steps
    }
  },
  methods: {
    launchTour () {
      this.$nextTick(() => {
        const tour = this.$shepherd({
          useModalOverlay: true,
          defaultStepOptions: {
            scrollTo: { behavior: 'smooth', block: 'center' }
          }
        })
        let defaultActions = [
          { action: tour.cancel,
            text: 'Quitter'
          },
          { action: tour.back,
            text: 'Retour'
          },
          { action: tour.next,
            text: 'Continuer'
          }
        ]
        let exitActions = [
          { action: tour.back,
            text: 'Retour'
          },
          { action: tour.complete,
            text: 'Terminer'
          }
        ]
        if (this.getCurrentTourSteps) {
          this.getCurrentTourSteps.forEach((step, index) => {
            tour.addStep({
              id: step.id,
              attachTo: step.attachTo ? step.attachTo : null,
              title: step.title,
              text: step.text,
              buttons: step.buttons === 'exit' ? exitActions : defaultActions
            })
          })
        }
        tour.start()
      })
    }
  }
}
