// import axios from '@/services/axios'
// import { groupBy, includes } from 'lodash-es'
import { groupBy } from 'lodash-es'
const state = {
  notifications: null,
  notificationsNotViewed: null,
  pendingRequestsDialog: false
}

const getters = {}

const actions = {
  async fetchNotifications ({ commit }) {
    // TRANSFORMER POUR QUE ÇA SOIT DES NOTIFS DU CHAT
    // try {
    //   await axios.get('/api/eleve/notifications').then(({ data }) => {
    //     if (data) {
    //       commit('setNotif', data)
    //     }
    //   })
    // } catch (e) {
    //   // console.error(e)
    // }
  },
  async  markNotifAsSeen ({ commit, state }, ids) {
    // try {
    //   await axios.put('/api/eleve/notifications/view', null, {
    //     params: {
    //       viewed_ids: ids
    //     }
    //   }).then(({ data }) => {
    //     const newNotifications = state.notifications.map(notification => {
    //       if (includes(ids, notification.id)) {
    //         notification.viewed = true
    //       }
    //       return notification
    //     })
    //     commit('setNotif', newNotifications)
    //   })
    // } catch (e) {
    //   // console.error(e)
    // }
  }
}

const mutations = {
  setNotif (state, payload) {
    let temp = payload
    temp.reverse()
    state.notifications = temp
    state.notificationsNotViewed = groupBy(payload, ({ viewed }) => Boolean(viewed))
  },
  setPendingRequestsDialog (state, payload) {
    state.pendingRequestsDialog = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
