import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import {
  faAt,
  faUser,
  faExclamationTriangle,
  faPlus as fadPlus,
  faMinus as fadMinus,
  faAppleAlt,
  faBooks,
  faBook,
  faBackpack,
  faShoppingCart,
  faHandsHelping,
  faCog,
  faLocation,
  faCalendarAlt,
  faUsersMedical,
  faUsersClass,
  faUsers,
  faChalkboardTeacher,
  faTrophyAlt,
  faFileAlt,
  faFolder,
  faPoll,
  faCommentSmile,
  faPaintBrushAlt,
  faEye,
  faEyeSlash,
  faFileDownload,
  faFileEdit,
  faPen,
  faUserClock,
  faHourglassStart,
  faHourglassEnd,
  faShapes,
  faBell,
  faBellExclamation,
  faHistory,
  faPalette,
  faPrint,
  faTrashAlt,
  faWaveSine,
  faHorizontalRule,
  faCircle,
  faLock,
  faLockOpen,
  faGraduationCap,
  faBirthdayCake,
  faSchool,
  faGrinTongueSquint,
  faCommentAltExclamation,
  faExclamationCircle,
  faBooksMedical,
  faSun,
  faMoonStars,
  faPowerOff,
  faPennant,
  faUserCheck,
  faFileCheck,
  faCheckCircle,
  faTimesCircle,
  faBolt,
  faCloud,
  faSunCloud,
  faClouds,
  faFog,
  faCloudsSun,
  faCloudSunRain,
  faCloudSun,
  faCloudShowers,
  faCloudShowersHeavy,
  faCloudDrizzle,
  faCloudHail,
  faSnowflakes,
  faWindsock,
  faHomeLg,
  faHeartCircle,
  faQuestionCircle,
  faSync,
  faSearch,
  faThLarge,
  faList,
  faPeopleArrows,
  faShareSquare,
  faAward,
  faTag,
  faBookReader,
  faDatabase
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faTimes as farTimes,
  faPlus as farPlus,
  faMinus as farMinus,
  faHeart as farHeart,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faLongArrowLeft as farLongArrowLeft,
  faLongArrowRight as farLongArrowRight
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCommentAltExclamation as fasCommentAltExclamation,
  faBooks as fasBooks,
  faHomeLg as fasHomeLg,
  faAppleAlt as fasAppleAlt,
  faBackpack as fasBackpack,
  faBars as fasBars,
  faUser as fasUser,
  faArrowAltDown as fasArrowAltDown,
  faTimes as fasTimes,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faGripVertical as fasGripVertical,
  faHeart as fasHeart,
  faEllipsisV as fasEllipsisV,
  faCheck as fasCheck,
  faHistory as fasHistory,
  faInfo as fasInfo,
  faGripHorizontal as fasGripHorizontal,
  faGripLines as fasGripLines,
  faCircle as fasCircle,
  faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown
} from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faAt,
  fasHistory,
  faUser,
  fasUser,
  fasBars,
  fasGripVertical,
  faExclamationTriangle,
  fadPlus, farPlus,
  fadMinus, farMinus,
  fasArrowAltDown,
  fasArrowRight, farArrowRight,
  fasArrowLeft, farArrowLeft,
  farLongArrowLeft,
  farLongArrowRight,
  fasTimes, farTimes,
  faAppleAlt, fasAppleAlt,
  faBooks, fasBooks,
  faBook,
  faBackpack, fasBackpack,
  faShoppingCart,
  faHandsHelping,
  faCog,
  faFileAlt,
  faFolder,
  faLocation,
  faCalendarAlt,
  faUsersMedical,
  faAward,
  faUsersClass,
  faUsers,
  faChalkboardTeacher,
  faTrophyAlt,
  faPoll,
  faCommentSmile,
  faPaintBrushAlt,
  faEye,
  faEyeSlash,
  faFileDownload,
  faUserClock,
  faHourglassStart,
  faHourglassEnd,
  faShapes,
  faBell,
  faBellExclamation,
  faExclamationCircle,
  faHistory,
  faPalette,
  faPrint,
  faTrashAlt,
  faWaveSine,
  faHorizontalRule,
  faCircle,
  faLock,
  faLockOpen,
  faGraduationCap,
  faBirthdayCake,
  faSchool,
  faGrinTongueSquint,
  faCommentAltExclamation, fasCommentAltExclamation,
  faBooksMedical,
  faSun,
  faMoonStars,
  faPowerOff,
  faPennant,
  faUserCheck,
  faFileCheck,
  faFileEdit,
  faPen,
  fasCheck,
  faCheckCircle,
  faTimesCircle,
  faBolt,
  faCloud,
  faSunCloud,
  faClouds,
  faFog,
  faCloudsSun,
  faCloudSunRain,
  faCloudSun,
  faCloudShowers,
  faCloudShowersHeavy,
  faCloudDrizzle,
  faCloudHail,
  faSnowflakes,
  faWindsock,
  faHomeLg, fasHomeLg,
  faHeartCircle,
  faQuestionCircle,
  faSearch,
  fasHeart, farHeart,
  fasEllipsisV,
  faSync,
  faThLarge,
  faList,
  faPeopleArrows,
  fasInfo,
  fasGripHorizontal,
  fasGripLines,
  faShareSquare,
  faTag,
  fasCircle,
  faBookReader,
  fasCaretUp,
  fasCaretDown,
  faDatabase
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
