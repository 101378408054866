<script>
export default {
  name: 'ModalFusion',
  computed: {
    showFusionCompte: {
      set (val) {
        this.$store.commit('Dialogs/setFusionCompte', val)
      },
      get () {
        return this.$store.state.Dialogs.showFusionCompte
      }
    }
  },
  data () {
    return {
      pseudoToFusion: null,
      codeToFusion: null,
      showError: false,
      loading: false,
      finish: false,
      confirm: false,
      confirmText: null
    }
  },
  methods: {
    confirmFusion () {
      if (this.pseudoToFusion && this.codeToFusion) {
        this.confirm = true
      }
    },
    async fusionCompte () {
      if (this.pseudoToFusion && this.codeToFusion) {
        this.exitConfirm()
        this.loading = true
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/comptes/userFusion.gql'),
          variables: {
            username: this.pseudoToFusion,
            access_code: this.codeToFusion
          },
          client: 'compte'
        }).then(({ data }) => {
          this.loading = false
          if (!data.user_fusion) {
            this.showError = true
          } else {
            this.$store.dispatch('User/fetchEnfants')
            this.finish = true
          }
        })
      } else {
        this.showError = true
      }
    },
    exitForm () {
      this.pseudoToFusion = null
      this.codeToFusion = null
      this.showFusionCompte = false
      this.confirmText = null
      this.finish = false
      this.confirm = false
      this.showError = false
    },
    exitConfirm () {
      this.confirmText = null
      this.confirm = false
    }
  }
}
</script>
<template lang="pug">
  v-dialog(v-model='showFusionCompte', content-class='pending-requests-dialog custom-dialog', max-width='500')
    v-dialog(v-model='confirm', persistent, content-class='pending-requests-dialog custom-dialog', max-width='400')
      v-dialog(v-model='loading', hide-overlay, persistent, width='400')
        v-card(color='primary', dark)
          v-card-text
            div.dialog-text.pa4.secondary--text
              div.b.f4.mb3.tc Validation en cours...
              v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)

      v-card.light
        v-card-text.dark--text(style='padding-top:40px')
          div.dialog-text(style='line-height:1.25')
            div.f5.tc.white--text Afin de confimer la fusion veuillez écrire <br> #[span.b.f3 FUSION] <br>dans le champ en dessous
          v-text-field.code-input(solo, hide-details, v-model='confirmText', clearable, @keyup.enter='fusionCompte')
          div
            v-btn(rounded, block, outlined, @click='exitConfirm', color='secondary', :disabled='loading || finish')
              span Annuler
        v-btn.secondary--text(dark, block, x-large, @click='fusionCompte', color='primary', depressed, :disabled='!confirmText  || confirmText !== "FUSION"')
          span.f4 Confirmer
    v-card.relative.light
      v-hover(v-slot:default="{ hover }")
        v-btn.dialog-button(@click='showFusionCompte = false', depressed, fab, x-large, color='primary')
          font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title.flex-column.secondary.white--text
        div.f3 Fusion de comptes
      v-card-text.dark--text()
        div.dialog-text
          div.f5.mb3 Veuillez renseigner le nom d'utilisateur ainsi que le mot de passe du compte à fusionner
          v-alert.light.animated.fadeInDown(v-if='showError', dense, text, type='error', background-color='light') Une erreur est survenue lors de la fusion du compte. Veuillez vérifier que le compte a fusionné est un compte enseignant.
        v-text-field.code-input(placeholder='@Utilisateur', solo, hide-details, v-model='pseudoToFusion', clearable, @keyup.enter='fusionCompte')
        v-text-field.code-input(placeholder="Code d'accès", solo, hide-details, v-model='codeToFusion', clearable, @keyup.enter='fusionCompte')
      v-btn(@click='confirm = true', color='primary', depressed, :disabled='!pseudoToFusion || !codeToFusion || loading || finish', block, x-large)
        span.f4 Fusionner
</template>

<style>
</style>
