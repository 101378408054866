import VueCookies from 'vue-cookies'
// import axios from 'axios'
import { apolloClientV2 } from '@/setup/apollo.js'

const state = {
  connected: false,
  profile: null,
  profileCompte: null,
  profileDokoma: null,
  requests: null,
  ecole: [],
  groupes: [],
  currentYear: null,
  createAccountDokoma: false,
  connectedDokoma: false,
  loginChat: false,
  role: null,
  relationEnAttente: [],
  relations: [],
  enfantsLoading: true,
  totRel: 0,
  refetchUserInfo: false,
  favoris: [],
  favTotal: 0,
  refetchEnfant: false
}

const getters = {}

const actions = {
  // async connectUser ({ commit, state, dispatch }, profile) {
  //   try {
  //     await axios.post('https://comptes.dokoma.com/api/v1/tokens.json?client_id=quizz&client_secret=dokoma', {
  //       user: { username: profile.username, access_code: profile.access_code }
  //     }).then((data) => {
  //       dispatch('login', data.data)
  //     })
  //   } catch (e) {
  //     // console.error(e)
  //   }
  // },
  // async loginChat ({ commit, state, dispatch }, profile) {
  //   // on va faire le login sur chat
  //   try {
  //     await this.$apollo.mutate({
  //       mutations: require('@/graphql/mutations/chat/loginUser.gql'),
  //       variables: {
  //         username: profile.username,
  //         accessCode: profile.access_code
  //       },
  //       client: 'chat'
  //     }).then((data) => {
  //       if (data.data.loginUser) {
  //         this.$cookies.set('chattokenclasseParent', data.data.loginUser)
  //         commit('setConnectedDokoma', true)
  //       }
  //     })
  //   } catch (e) {
  //     console.error(e)
  //   }
  // },
  async fetchFavoris ({ commit, dispatch }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/searchCommunFavoris.gql'),
        // variables: { filtre: { types: ['BOUTIQUE_ITEM'] } },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        commit('initFavoris', data.searchCommunFavoris.resultat)
        commit('setFavTotal', data.searchCommunFavoris.total)
        dispatch('fetchMoreFav', 1)
      })
    } catch (e) {
      console.error('erreur lors de la récupération des favoris')
    }
  },
  async fetchMoreFav ({ commit, state, dispatch }, value) {
    if (state.favTotal > state.favoris.length) {
      try {
        const newPage = value + 1
        await apolloClientV2.query({
          query: require('@/graphql/queries/v2/searchCommunFavoris.gql'),
          variables: { page: newPage },
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          commit('addBatchFav', data.searchCommunFavoris.resultat)
          dispatch('fetchMoreFav', newPage)
        })
      } catch (e) {
        console.error('erreur lors de la récupération des favoris')
      }
    }
  },
  login ({ commit, state, dispatch }, connexion) {
    // console.log('coucou')
    // VueCookies.set('classParentToken', connexion.token, new Date(parseInt(connexion.date_expiration.toString() + '000')), '/', process.env.VUE_APP_DOMAIN)
    let domain = window.location.hostname
    if (domain !== 'localhost') {
      const data = domain.split('.')
      data[0] = ''
      domain = data.join('.')
    }
    VueCookies.set('classParentToken', connexion.token, new Date(parseInt(connexion.date_expiration.toString() + '000')), '/', domain)
    commit('setConnected', true)
    commit('setProfile', connexion.user)
  },
  async fetchProfile ({ dispatch, commit, state }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseUsager.gql')
      }).then(({ data }) => {
        if (data.salleClasseUsager) {
          commit('setSchool', data.salleClasseUsager.compagnies)
        } else {
          if (VueCookies.get('classParentToken')) {
            console.log('ON A pas dusager mais Un TOKEN')
          }
          const errormsg = { msg: 'usager vide' }
          throw errormsg
        }
      })
      return true
    } catch (e) {
      console.error('error', e)
      return false
    }
  },
  async fetchMinProfile ({ commit }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseUsagerMin.gql')
      }).then(({ data }) => {
        if (data.salleClasseUsager) {
          // VueCookies.set('classParentUser', { username: data.salleClasseUsager.info.username, access_code: data.salleClasseUsager.info.accessCode }, 60 * 60 * 24, '/', process.env.VUE_APP_DOMAIN)
          let domain = window.location.hostname
          if (domain !== 'localhost') {
            const data = domain.split('.')
            data[0] = ''
            domain = data.join('.')
          }
          VueCookies.set('classParentUser', { username: data.salleClasseUsager.info.username, access_code: data.salleClasseUsager.info.accessCode }, 60 * 60 * 24, '/', domain)
          // test pour le multi domain
          commit('setProfile', data.salleClasseUsager)
          commit('setRoles', data.salleClasseUsager.roles)
          commit('setConnected', true)
        } else {
          commit('setConnected', false)
        }
      })
    } catch (e) {
      commit('setConnected', false)
      console.error(e)
    }
  },
  async fetchEnfants ({ commit }) {
    commit('setEnfantsLoading', true)
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseUsagerRelationsSuperieurs.gql'),
        variables: { limit: 100 },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        if (data.salleClasseUsagerRelationsSuperieurs) {
          // TODO PAGINATION
          // console.log(data.salleClasseUsagerRelationsSuperieurs.total)
          commit('setTotRels', data.salleClasseUsagerRelationsSuperieurs.total)
          commit('setRels', data.salleClasseUsagerRelationsSuperieurs.resultat)
          commit('setEnfantsLoading', false)
        }
      })
    } catch (e) {
      console.error('error', e)
    }
  },
  async fetchGroupes ({ commit }) {
    try {
      await apolloClientV2.query({
        // TODO REMPLACER PAR SEARCH SALLE CLASSES
        query: require('@/graphql/queries/v2/searchSalleClasses.gql'),
        // variables: { idsEleves: state.} LES IDS DE ELEVE
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        commit('setGroupes', data.searchSalleClasses.resultat)
      })
    } catch (e) {
      console.error('error fetch groupes', e)
    }
  },
  async fetchRequest ({ commit }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseUsagerDemandesRecues.gql'),
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        // todo pagination
        if (data.salleClasseUsagerDemandesRecues && data.salleClasseUsagerDemandesRecues.total > 0) {
          commit('setWaitinRelation', data.salleClasseUsagerDemandesRecues.resultat)
        }
      })
    } catch (e) {
      console.error(e)
    }
  },
  async logOut ({ commit, state }) {
    let domain = window.location.hostname
    if (domain !== 'localhost') {
      const data = domain.split('.')
      data[0] = ''
      domain = data.join('.')
    }

    commit('setRoles', [])
    commit('setConnected', false)
    VueCookies.remove('classParentToken', '/', domain)
    VueCookies.remove('classParentUser', '/', domain)
    VueCookies.remove('classParent', '/', domain)
    commit('setUserAvatar', null)
    commit('setProfile', null)
    commit('setSchool', [])
    commit('setLoginChat', false)
    return null
  }
}

const mutations = {
  setFavTotal (state, payload) {
    state.favTotal = payload
  },
  initFavoris (state, payload) {
    state.favoris = payload
  },
  addBatchFav (state, payload) {
    state.favoris.push(...payload)
  },
  addRemoveFavoris (state, payload) {
    const findex = state.favoris.findIndex(e => e.id === payload.id)
    if (findex >= 0) {
      state.favoris.splice(findex, 1)
    } else {
      state.favoris.push(payload)
    }
  },
  setGroupes (state, payload) {
    state.groupes = payload
  },
  setTotRels (state, payload) {
    state.totRel = payload
  },
  setSchool (state, payload) {
    state.ecoles = payload
  },
  setEnfantsLoading (state, payload) {
    state.enfantsLoading = payload
  },
  setRels (state, payload) {
    state.relations = payload
  },
  setWaitinRelation (state, payload) {
    state.relationEnAttente = payload
  },
  setRoles (state, payload) {
    state.roles = payload
  },
  setUserAvatar (state, payload) {
    state.userAvatar = payload
  },
  setProfileCompte (state, payload) {
    state.profileCompte = payload
  },
  setLoginChat (state, payload) {
    state.loginChat = payload
  },
  setCurrentYear (state, payload) {
    state.currentYear = payload
  },
  setRequest (state, payload) {
    state.requests = payload
  },
  setProfileDokoma (state, payload) {
    state.profileDokoma = payload
  },
  setProfile (state, payload) {
    state.profile = payload
    if (payload && !payload.username) {
      state.createAccountDokoma = true
    }
  },
  setConnected (state, payload) {
    state.connected = payload
  },
  setConnectedDokoma (state, payload) {
    state.connectedDokoma = payload
  },
  setCreateAccountDokoma (state, payload) {
    state.createAccountDokoma = payload
  },
  setRefetchUserInfo (state, payload) {
    state.refetchUserInfo = payload
  },
  setRefetchEnfant (state, payload) {
    state.refetchEnfant = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
