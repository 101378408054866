export default {
  computed: {
  },
  methods: {
    async boutiqueItemSup (id) {
      const info = await this.getGraphArbreData(id)
      this.infoSup = info
    },
    async getGraphArbreData (arbreId) {
      try {
        const { data } = await this.$apollo.query({
          query: require('@/graphql/queries/v2/opti/graphArbreData.gql'),
          variables: { id: arbreId }
        })
        return JSON.parse(data.graphArbre.data)
      } catch (e) {
        return {}
      }
    },
    async getBoutiqueItemContenu (itemSlug) {
      try {
        const { data } = await this.$apollo.query({
          query: require('@/graphql/queries/v2/boutiqueItemBy.gql'),
          variables () {
            return {
              attrs: { slug: itemSlug }
            }
          }
        })
        return data.boutiqueItemBy
      } catch (e) {
        return null
      }
    },
    async getMultipleGraphArbreData (graphArbreIds) {
      const graphArbreDataSettled = await Promise.allSettled(graphArbreIds.map(id => this.getGraphArbreData(id)))
      const partialGraphArbreData = graphArbreDataSettled.map(gads => gads.value)
      const graphArbreData = this.completeGraphArbreDataListWithNatures(partialGraphArbreData)
      return graphArbreData
    },
    async completeGraphArbreDataListWithNatures (graphArbreDataList) {
      // Get lists of ids
      const allNatures = {}
      const allMatieresSet = new Set()
      const allNiveauxSet = new Set()
      graphArbreDataList.forEach(d => {
        Object.keys(d.natures).forEach(n => { allNatures[n] = { titre: null } })
        d.matiere_ids.forEach(mid => allMatieresSet.add(mid))
        d.niveau_ids.forEach(nid => allNiveauxSet.add(nid))
      })

      // Fetch info (this is not optimised since every request is chained)
      const exerciceNatures = await this.natureNamesFromNatureIds(Object.keys(allNatures))
      const communMatieres = await this.matieresNamesFromMatieresIds([...allMatieresSet])
      const communNiveaux = await this.niveauxNamesFromNiveauxIds([...allNiveauxSet])

      // Hydrate the objects with fetched information
      const hydratedGadl = graphArbreDataList.map(gad => {
        const natures = []
        for (const nidStr in gad.natures) {
          const nid = Number(nidStr)
          const nature = exerciceNatures.find(n => n.id === nid)
          natures.push({
            id: nid,
            titre: nature.titre,
            count: gad.natures[nidStr],
            pluriel: nature.pluriel
          })
        }

        const matieres = []
        for (const mid of gad.matiere_ids) {
          const matiere = communMatieres.find(m => m.id === mid.toString())
          matieres.push({
            id: mid,
            titre: matiere.titre,
            count: 1
          })
        }

        const niveaux = []
        for (const nid of gad.niveau_ids) {
          const niveau = communNiveaux.find(n => n.id === nid.toString())
          niveaux.push({
            id: nid,
            titre: niveau.titre ?? '',
            count: 1
          })
        }

        gad.nat = natures
        gad.matieres = matieres
        gad.niveaux = niveaux
        return gad
      })
      return hydratedGadl
    },
    async natureNamesFromNatureIds (natureIds) {
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/exercicesNatures.gql'),
        client: 'plus',
        variables: { ids: natureIds.map(e => Number(e)) }
      })
      return data.exercices_natures
    },
    async matieresNamesFromMatieresIds (matieresIds) {
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/communMatieres.gql'),
        variables: { ids: matieresIds.map(e => Number(e)) }
      })
      return data.communMatieres
    },
    async niveauxNamesFromNiveauxIds (niveauxIds) {
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/communNiveaux.gql'),
        variables: { ids: niveauxIds.map(e => Number(e)) }
      })
      return data.communNiveaux
    }
  }
}
