import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user'
import App from './modules/app'
import Preferences from './modules/preferences'
import Layout from './modules/layout'
import Chatbot from './modules/chatbot'
import Bibliotheque from './modules/bibliotheque'
import Notifications from './modules/notifications'
import Dialogs from './modules/dialogs'
import Exercices from './modules/exercices'
// import Groupes from './modules/groupes'
import Meteo from './modules/meteo'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    User,
    App,
    Preferences,
    Layout,
    Chatbot,
    Bibliotheque,
    Notifications,
    Dialogs,
    Exercices,
    // Groupes,
    Meteo
  }
})
