import VueCookies from 'vue-cookies'
// import axios from '@/services/axios'
import { apolloClientPlus } from '@/setup/apollo.js'

const state = {
  items: [],
  listeBiblioItems: [],
  listesBiblio: [
    // {
    //   titre: 'Récemment consulté',
    //   icon: 'history',
    //   items: [],
    //   permanent: true,
    //   disableDrag: true
    // }
  ],
  biblioFilter: null,
  nombrePageDocument: 0,
  showOnlyFavs: false,
  biblioOrder: null
}

const getters = {}

const actions = {
  fetchListes ({ state, commit }) {
    if (VueCookies.get('listes_biblio')) {
      commit('initListesBiblio', JSON.parse(VueCookies.get('listes_biblio')))
    }
  },
  async fetchItems ({ commit }) {
    try {
      await apolloClientPlus.query({
        query: require('@/graphql/queries/plus/glossaires.gql'),
        variables: { tag_invisibles: ['bibliothèque eleve', 'bibliothèque enseignant', 'bibliothèque parent'] }
      }).then(({ data }) => {
        commit('setItems', data.graphs_par_tag)
      })
    } catch (e) {
      // console.error(e)
    }
  }
}

const mutations = {
  addNombrePageDocument (state, payload) {
    state.nombrePageDocument += payload
  },
  setBiblioFilter (state, payload) {
    state.biblioFilter = payload
  },
  setItems (state, payload) {
    state.items = payload
  },
  initListesBiblio (state, payload) {
    state.listesBiblio = payload
  },
  setListesBiblio (state, payload) {
    const findE = state.listesBiblio.findIndex((e) => e.titre === payload.titre)
    if (findE >= 0) {
      state.listesBiblio.splice(findE, 1)
    } else {
      state.listesBiblio.push(payload)
    }
    VueCookies.set('listes_biblio', JSON.stringify(state.listesBiblio))
  },
  setListesBiblioItems (state, payload) {
    const item = state.listesBiblio[payload.target].items.findIndex((e) => e.id === payload.produit.id)
    if (item >= 0) {
      state.listesBiblio[payload.target].items.splice(item, 1)
    } else {
      state.listesBiblio[payload.target].items.push(payload.produit)
    }
    VueCookies.set('listes_biblio', JSON.stringify(state.listesBiblio))
  },
  setShowOnlyFavs (state, payload) {
    state.showOnlyFavs = payload
  },
  setBiblioOrder (state, payload) {
    state.biblioOrder = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
