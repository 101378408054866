<template lang="pug">
  .detail-selection
    v-list-item-subtitle.i(v-if='parent', style='white-space: normal') {{parent.name}}
     v-list-item-subtitle.i(v-if='enfant', style='white-space: normal') {{enfant.name}}
    //- pre {{parent}} test
</template>

<script>
export default {
  name: 'DetailSelection',
  props: {
    treeviewExercices: { type: Array, required: false, default: () => [] },
    selection: { type: Object, required: false, default: () => null }
  },
  computed: {
    parent () {
      let temp = null
      if (this.selection && this.treeviewExercices) {
        temp = this.treeviewExercices.find(e => e.id === this.selection.parent)
        if (!temp) {
          temp = this.treeviewExercices.find(e => e.children.find(c => c.id === this.selection.parent))
        }
      }
      return temp
    },
    enfant () {
      let temp = null
      if (this.parent && this.selection) {
        temp = this.parent.children.find(e => e.id === this.selection.parent)
      }
      return temp
    }
  }
}
</script>

<style>
</style>
