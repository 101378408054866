<script>
import NavigationSidebar from '@/components/layout/navigationSidebar.vue'
import NotificationSidebar from '@/components/notifications/notificationSidebar.vue'
import BackgroundTexture from '@/components/home/backgroundTexture'
import LayoutTopBanner from '@/components/layout/layoutTopBanner.vue'
import LayoutHeader from '@/components/layout/layoutHeader.vue'
import LayoutFooter from '@/components/layout/layoutFooter.vue'
import LayoutBottomNav from '@/components/layout/layoutBottomNav.vue'
import DemandeAjout from '@/components/dialogs/actions/demandeAjout.vue'
import EditTableauBlanc from '@/components/dialogs/actions/editTableauBlanc.vue'
import DeleteTableauBlanc from '@/components/dialogs/actions/deleteTableauBlanc.vue'
import ModalFusion from '@/components/dialogs/profile/modalFusion.vue'
import FeedbackSnackbar from '@/components/notifications/feedbackSnackbar'
import TutorialDispatcher from '@/components/dialogs/tutorials/tutorialDispatcher.vue'

import AjoutCode from '@/components/dialogs/actions/ajoutCode.vue'
import AjoutEnfant from '@/components/dialogs/actions/ajoutEnfant.vue'

import UserCodeConfirmation from '@/components/dialogs/actions/userCodeConfirmation.vue'
import RetrieveIdentifications from '@/components/dialogs/actions/retrieveIdentifications.vue'
import SelectExercices from '@/components/dialogs/actions/selectExercices.vue'
import SelectEleves from '@/components/dialogs/actions/selectEleves.vue'

import TutorialsContent from '@/mixins/tutorialsContent.js'

export default {
  name: 'App',
  components: {
    FeedbackSnackbar,
    BackgroundTexture,
    LayoutTopBanner,
    LayoutHeader,
    LayoutFooter,
    LayoutBottomNav,
    NavigationSidebar,
    NotificationSidebar,
    DemandeAjout,
    ModalFusion,
    AjoutCode,
    AjoutEnfant,
    UserCodeConfirmation,
    RetrieveIdentifications,
    TutorialDispatcher,
    EditTableauBlanc,
    DeleteTableauBlanc,
    SelectExercices,
    SelectEleves
  },
  mixins: [TutorialsContent],
  data () {
    return {
      interChat: null,
      window: {
        width: 0
      },
      stockGroupesMessage: null
    }
  },
  computed: {
    expandMenuConditions () {
      let val = false
      if ((this.$store.state.App.windowSize.width > 900 && this.$store.state.App.windowSize.width < 1200) || (this.$route && this.$route.name && (this.$route.name.includes('bibliotheque') || this.$route.name.includes('calendrier')))) {
        val = true
      }
      return val
    },
    getMinifiedNav () {
      if (this.$route && this.$route.name && (this.$route.name.includes('bibliotheque') || this.$route.name.includes('calendrier'))) {
        return true
      } else {
        return false
      }
    },
    footerVisible () {
      if (this.$route && this.$route.name) {
        if (this.$route.name.includes('connect') || this.$route.name.includes('calendrier') || this.$route.name.includes('bibliotheque')) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    newMess: {
      get () { return this.$store.state.App.newMess },
      set (val) { this.$store.commit('App/setNewMess', val) }
    },
    notificationsSidebar: {
      get () { return this.$store.state.App.notificationsSidebar },
      set (val) { this.$store.commit('App/setNotificationsSidebar', val) }
    },
    mobileTemplate: {
      get () { return this.$store.state.App.mobileTemplate },
      set (val) { this.$store.commit('App/setMobileTemplate', val) }
    },
    windowSize: {
      get () { return this.$store.state.App.windowSize },
      set (val) { this.$store.commit('App/setWindowSize', val) }
    },
    lockedScroll: {
      get () { return this.$store.state.App.lockedScroll },
      set (val) { this.$store.commit('App/setLockedScroll', val) }
    },
    showExercicesSelection () {
      return this.$store.state.Dialogs.showExercicesSelection
    },
    showElevesSelection () {
      return this.$store.state.Dialogs.showElevesSelection
    }
  },
  async mounted () {
    this.$store.dispatch('Bibliotheque/fetchItems')
    window.addEventListener('resize', this.handleResize)
    this.$nextTick(() => {
      this.handleResize()
    })
    // console.log('window pathname', window.location.pathname)
  },
  async created () {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpdateUI = true
      })
    }
    this.$store.commit('App/setRouteName', this.$route.name ? this.$route.name : 'index')
    await this.$store.dispatch('Meteo/fetchMeteo')
    this.$store.commit('Meteo/setMeteoLoading', false)
  },
  destroyed () {
    window.clearInterval(this.interChat)
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    '$route': {
      immediate: true,
      handler (newVal) {
        if (newVal && newVal.name) {
          // Pour afficher drift seulement sur l'accueil.
          if (window.document.getElementsByClassName('drift-conductor-item drift-frame-controller')) {
            let widget = window.document.getElementsByClassName('drift-conductor-item drift-frame-controller')
            if (widget && widget.length > 0) {
              if (newVal.name === 'home') {
                widget[0].style.display = 'block'
              } else {
                widget[0].style.display = 'none'
              }
            }
          }
        }
        // pour afficher les tutoriels
        // console.log('this.routename', newVal, this.$store.state.Preferences.tutorials)
        if (this.$store.state.Preferences.tutorials) {
          setTimeout(() => {
            Object.keys(this.$store.state.Preferences.tutorials).forEach(el => {
              // console.log(this.$store.state.Preferences.tutorials[el] !== true, 'el')
              if (this.$route.name === el && this.$store.state.Preferences.tutorials[el] !== true) {
                // console.log('AFFICHE MOI UN TUTO', el)
                this.$store.state.Dialogs.showTutorialDialog = true
              }
            })
          }, 5000)
        }
      }
    },
    '$store.state.User.connected': {
      deep: true,
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.$store.dispatch('Preferences/fetchPreferences')
          // if (!this.$store.state.Exercices.devoirsRecent) {
          //   this.$store.dispatch('Exercices/fetchDevoirsRecent')
          // }
          if (!this.$store.state.Notifications.notifetched) {
            this.$store.dispatch('Notifications/fetchNotifications')
          }
          this.$store.dispatch('Bibliotheque/fetchItems')
          this.$store.dispatch('User/fetchProfile')
          // les 3 seuls intéssant dans le store lors du lancement de l'app
          this.$store.dispatch('User/fetchRequest')
          this.$store.dispatch('User/fetchEnfants')
          this.$store.dispatch('User/fetchGroupes')
          this.$store.dispatch('App/fetchHisto')
          this.$store.dispatch('User/fetchFavoris')
        } else {
          this.$store.dispatch('Preferences/resetPreferences')
        }
      }
    },
    mobileTemplate (newVal) {
      if (!newVal) {
        let secondaryBar = window.document.getElementById('secondaryBar')
        if (secondaryBar) {
          secondaryBar.style.transform = 'translate(0)'
        }
      }
    },
    lockedScroll (newVal) {
      if (newVal) {
        document.documentElement.setAttribute('style', 'overflow-y:hidden !important')
      } else {
        document.documentElement.setAttribute('style', 'overflow-y:auto !important')
      }
    }
  },
  methods: {
    handleResize () {
      if (this.windowSize.width !== window.innerWidth || this.windowSize.height !== window.innerHeight) {
        this.windowSize.width = window.innerWidth
        this.windowSize.height = window.innerHeight
        if (this.windowSize.width < 1601) {
          this.notificationsSidebar = false
        }
        if (this.windowSize.width < 901) {
          this.mobileTemplate = true
        } else {
          this.mobileTemplate = false
        }
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    },
    async loginChatMethod () {
      try {
        const user = window.$cookies.get('classParentUser').username
        const access = window.$cookies.get('classParentUser').access_code
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/chat/loginUser.gql'),
          client: 'chat',
          variables: {
            username: user,
            accessCode: access
          }
        }).then((data) => {
          if (data.data.loginUser) {
            this.$cookies.set('chattokenclasseParent', data.data.loginUser)
            this.$store.commit('User/setConnectedDokoma', true)
            this.interChat = setInterval(() => {
              this.startInterval()
            }, 5000)
          }
        })
      } catch (e) {
        /* eslint-disable */
        console.error(e)
      }
    },
    async startInterval () {
      this.$apollo.query({
        query: require('@/graphql/queries/chat/chatGroupes.gql'),
        client: 'chat',
        fetchPolicy: 'network-only'
      }).then((data) => {
        if (data.data.chatGroupes) {
          if (!this.stockGroupesMessage) {
            this.stockGroupesMessage = data.data.chatGroupes
          } else {
            data.data.chatGroupes.forEach((groupe) => {
              // on va chercher le bon
              let temp = this.stockGroupesMessage.find((el) => el.id === groupe.id)
              // temp contient messages on regarde si le premier message est inclue ou non dans groupe
              if (temp && groupe.messages.length > 0 && !temp.messages.find((mes) => mes.id === groupe.messages[0].id)) {
                // eslint
                this.$store.commit('App/setNewMess', true)
                this.stockGroupesMessage = data.data.chatGroupes
              }
            })
          }
        }
      })
    }
  }
}
</script>
<template lang='pug'>
  v-app(dark, :class='{"footer-visible" : footerVisible, "login-view" : ($route.name && $route.name.includes("connect")), "custom-layout-view" : getMinifiedNav}')
    //- dialogs générales
    ajout-enfant
    ajout-code
    demande-ajout
    modal-fusion
    user-code-confirmation
    retrieve-identifications
    feedback-snackbar
    tutorial-dispatcher(@startTour='launchTour')
    edit-tableau-blanc
    delete-tableau-blanc
    select-exercices(v-if='showExercicesSelection')
    select-eleves(v-if='showElevesSelection')
    //----------------------------
    background-texture(v-if='$route.name && !$route.name.includes("connect")')
    layout-top-banner.animated.fadeInDown(id='s-step-1')

    template(v-if='$store.state.User.connected')
      notification-sidebar()
      navigation-sidebar.animated.fadeInLeft(v-if='!mobileTemplate')
      layout-bottom-nav.animated.fadeInUp(v-if='mobileTemplate')
    //- pre(v-if='window.location') {{window.location.pathname}}
    v-main()
      //- layout-header.animated.fadeIn(v-if='!mobileTemplate', @toggleMenu='showMenu = !showMenu')
      vue-page-transition.h-100.w-100(name='fade-in-right')
        router-view
    layout-footer(v-if='footerVisible', :class='{"custom-margin" : expandMenuConditions}')
</template>
<style lang='sass'>
  @import '~shepherd.js/dist/css/shepherd.css'

  @import 'src/styles/vendors/_vuetify'
  @import 'src/styles/vendors/_v-tooltip'
  @import 'src/styles/vendors/_shepherd'

  // @import 'src/styles/vendors/_normalize.sass'
  @import 'src/styles/layout/_navigation'
  @import 'src/styles/components/dialogs/basicDialog'
  @import 'src/styles/components/dialogs/_selectionDialog'

  @import 'src/styles/components/shared/_pageHeader.sass'
  @import 'src/styles/components/shared/_splitPageLayout'
  @import 'src/styles/components/shared/_common.sass'
  @import 'src/styles/components/presentation/_baseCard'
  @import 'src/styles/components/shared/_pageActions'
   
  @import 'src/styles/vendors/_drift-widget.sass'
  body
    margin: 0 !important
  .drift-frame-controller
    width: 80px !important
  .vuetify-badge
    .v-badge__wrapper
      .v-badge__badge
        .v-icon
          font-size: 12px !important
          color: white
  .v-btn__content
    text-transform: none
    letter-spacing: normal
  .v-navigation-drawer__border
    display: none
  .v-application
    background-color: var(--v-accent-base) !important
    &.theme-dark
      background-color: var(--v-accent-base) !important
    &.login-view
      .v-main
        .v-main__wrap
          padding: 0
    .v-main
      // display: flex
      padding-top: 40px !important
      .v-main__wrap
        padding: 20px
    @media all and (min-width: $medium)
      &.custom-layout-view
        .v-main
          padding-left: 80px !important
    @media all and (max-width: $medium)
      .v-main
        .v-main__wrap
          padding: 0
      &.footer-visible
        padding-bottom: 56px
    @media all and (max-width: $small)
      .v-main
        .v-main__wrap
          padding: 0
</style>
