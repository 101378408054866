export default {
  methods: {
    async destroyCalendrierElement (id) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/destroyCalendrierElement.gql'),
          variables: {
            id: id
          }
        })
        if (!data.destroyCalendrierElement || data.destroyCalendrierElement.errors) {
          return false
        } else {
          return true
        }
      } catch (e) {
        return false
      }
    },
    async updateCalendrierElement (id, attrs) {
      return this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/updateCalendrierElement.gql'),
        variables: {
          id,
          attrs
        }
      })
        .then(({ data }) => {
          if (!data.updateCalendrierElement || data.updateCalendrierElement.errors) {
            return false
          } else {
            return true
          }
        })
        .catch((e) => {
          return false
        })
    },
    async enleverUsagerCalendrierElement (id, usagers) {
      return this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/enleverUsagerCalendrierElement.gql'),
        variables: {
          id,
          usagers
        }
      })
        .then(({ data }) => {
          if (!data.enleverUsagerCalendrierElement || data.enleverUsagerCalendrierElement.errors) {
            return false
          } else {
            return true
          }
        })
        .catch((e) => {
          return false
        })
    }
  }
}
