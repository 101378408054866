export default {
  data () {
    return {
    }
  },
  computed: {
    newMess: {
      get () { return this.$store.state.App.newMess },
      set (val) { this.$store.commit('App/setNewMess', val) }
    }
  },
  methods: {
    goChat () {
      window.open('https://blahblah.dokoma.com/clavardage/#/?dokUANE=' + this.$cookies.get('classParentUser').username + '&access=' + this.$cookies.get('classParentUser').access_code, '_blank')
      this.newMess = false
    }
  }
}
