import { render, staticRenderFns } from "./notificationSidebar.vue?vue&type=template&id=3e78bf35&lang=pug&"
import script from "./notificationSidebar.vue?vue&type=script&lang=js&"
export * from "./notificationSidebar.vue?vue&type=script&lang=js&"
import style0 from "./notificationSidebar.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VCard,VDivider,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VNavigationDrawer})
