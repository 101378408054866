<script>
import groupBy from 'lodash-es'
import FormatDate from '@/mixins/formatDate.js'
export default {
  name: 'NotificationSidebar',
  mixins: [FormatDate],
  data () {
    return {
    }
  },
  computed: {
    getCurrentDate () {
      let splitDate = {
        day: new Date().toLocaleString('fr-FR', { day: 'numeric' }),
        month: new Date().toLocaleString('fr-FR', { month: 'long' }),
        year: new Date().toLocaleString('fr-FR', { year: 'numeric' })
      }
      return splitDate
    },
    notificationsSidebar: {
      get () { return this.$store.state.App.notificationsSidebar },
      set (val) { this.$store.commit('App/setNotificationsSidebar', val) }
    },
    mobileTemplate: {
      get () { return this.$store.state.App.mobileTemplate },
      set (val) { this.$store.commit('App/setMobileTemplate', val) }
    },
    notifications () {
      let temp = []
      // STRUCTURE
      // title subtitle date type
      // le type permet d'ajouter un bouton pour afficher l'action
      this.$store.state.User.relationEnAttente.forEach((element) => {
        temp.push({ date: this.formatDate(element.createdAt), title: "Demande d'ajout de " + element.emetteur.info.prenom + ' ' + element.emetteur.info.nom, subtitle: "Une nouvelle demande d'ajout vous attend", type: 'demande_ajout' })
      })
      return groupBy(temp, 'date')
    }
  }
}
</script>

<template lang='pug'>
  v-navigation-drawer.notifications-sidebar(app, :permanent='($store.state.App.windowSize.width > 1600 && notificationsSidebar)', :temporary='$store.state.App.windowSize.width < 1601', right, v-model='notificationsSidebar', width='300')
    template(slot='prepend')
      v-list-item.primary.notifications__header(two-line)
        v-list-item-content
          v-list-item-title.white--text Notifications
          v-list-item-subtitle.i.white--text(v-if='notifications.length > 0')
            div {{ notifications.length }} nouvelles notifications
          v-list-item-subtitle.i.white--text(v-else)
            div 10 nouvelles notifications
    //- v-divider
    perfect-scrollbar.notifications__content(:options='{wheelPropagation: false}')
      div.current-date__wrapper.date-header
        div.f2 {{getCurrentDate.day}}
        div.f6.b.ttu.mt1 {{getCurrentDate.month}}
      v-divider
      div.notification-cards__wrapper
        template(v-if='notifications.length > 0')
          template(v-for='(item, index) in notifications')
            v-card.notification-card
              div.event-infos
                .event-subtitle {{item}}

                .event-title {{notifications[item].title}}
                .event-subtitle
                  div {{item.subtitle}}
                  //- div.actions
                    v-btn(v-if='item.type === "demande_ajout"', @click='$store.commit("Notifications/setPendingRequestsDialog", true)') Voir les demandes d'ajout
              div.event-action(v-if='item.type === "demande_ajout"', @click='$store.commit("Notifications/setPendingRequestsDialog", true)')
                font-awesome-icon(:icon="['far', 'arrow-right']")
        //- template(v-for='(n, index) in 6')
          //- v-card.notification-card(v-if='index < 2')
            div Demande d'ajout
          v-card.event-card
            .event-color
            div.event-infos
              v-chip.mb2.error(v-if='index % 2 === 1', x-small, style='padding:3px 5px')
                //- font-awesome-icon.f7.mr2.error--text(:icon="['fad', 'hourglass-end']")
                | Date limite
              .event-title Titre de l'événement
              .event-subtitle Calendrier scolaire

            div.event-action
              font-awesome-icon(:icon="['far', 'arrow-right']")

      //- div.current-date__wrapper.date-header
        div.f2 15
        div.f6.b.ttu.mt1 {{getCurrentDate.month}}
      //- div.notification-cards__wrapper
        template(v-for='(n, index) in 10')
          v-card(v-if='index % 2 === 1')
            v-card-title Événement
          v-card(v-else)
            v-card-title Notification
      div.pa2
        v-btn.secondary--text(block, rounded, to='/calendrier', large, color='primary', depressed) Voir mon calendrier
      //- v-list(two-line, dense)
        template(v-if='notifications.length > 0')
          template(v-for='(item, index) in notifications')
            v-subheader(:key='index', v-text='item.date')
            v-list-item
              v-list-item-content
                v-list-item-title {{item.title}}
                v-list-item-subtitle
                  div {{item.subtitle}}
                  div.actions
                    v-btn(v-if='item.type === "demande_ajout"', @click='$store.commit("Notifications/setPendingRequestsDialog", true)') Voir les demandes d'ajout
            v-divider(inset=true, v-if='index < notifications.length - 1')
        template(v-else)
          v-list-item()
            v-list-item-content
              v-list-item-title Aucune notification
              v-list-item-subtitle Vous êtes à jour!
          //- v-list-item()
            v-list-item-content
              //- v-list-item-title Aucune notification
              //- v-list-item-subtitle Vous êtes à jour!
</template>
<style lang='sass'>
.notifications-sidebar
  box-shadow: -2px 0px 20px #00000021
  height: calc(100vh - 40px) !important
  height: calc(var(--vh, 1vh) * 100 - 40px) !important
  top: 40px !important
  // border-left: solid 1px var(--v-secondary-base)
  .notifications__header
    .v-list-item__title
      font-family: $display-font
      font-weight: $bold
      font-size: 24px
      color: white
  .v-navigation-drawer__content
    overflow: hidden

    .notifications__content
      overflow-y: auto
      height: 100%
      .date-header
        background-color: var(--v-light-base)
        line-height: 1
        padding: 10px 16px
        position: sticky
        top: 0
        z-index: 10
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
        // background-color: white
      .notification-cards__wrapper
        padding: 16px
        .v-card
          &:not(:last-child)
            margin-bottom: 10px
        .event-card, .notification-card
          cursor: pointer
          min-height: 80px
          display: flex
          align-items: center
          overflow: hidden
          &:hover
            .event-action
              color: white
              background-color: var(--v-secondary-base)
          .event-color
            width: 8px
            background-color: orange
            // height: 100%
            align-self: stretch
          .event-infos
            padding: 10px
            display: flex
            flex-direction: column
            justify-content: center
            align-items: flex-start
            align-self: stretch
            flex-grow: 1
            .event-subtitle
              font-size: 12px
              color: grey
            .event-title
              font-size: 16px
          .event-action
            display: flex
            align-items: center
            padding: 5px 10px
            background-color: #f3f3f3
            color: var(--v-secondary-base)
            // border-left: solid 1px var(--v-secondary-base)
            border-radius: 0
            align-self: stretch
</style>
