<script>
import HomeTutorial from '@/components/dialogs/tutorials/homeTutorial.vue'
import DevoirTutorial from '@/components/dialogs/tutorials/devoirTutorial.vue'
import FallbackTutorial from '@/components/dialogs/tutorials/fallbackTutorial.vue'

export default {
  name: 'tutorialDispatcjer',
  components: {
    HomeTutorial,
    DevoirTutorial,
    FallbackTutorial
  },
  data () {
    return {
    }
  },
  watch: {
  },
  computed: {
    showTutorialDialog: {
      set (val) { this.$store.commit('Dialogs/setShowTutorialDialog', val) },
      get () { return this.$store.state.Dialogs.showTutorialDialog }
    },
    tutorials: {
      get () { return this.$store.state.Preferences.tutorials },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'tutorials', data: val })
      }
    }
  },
  methods: {
    launchTour () {
      this.$emit('startTour')
      this.showTutorialDialog = false
    },
    updateViewedTutorials (tuto) {
      let tutorialsList = this.$store.state.Preferences.tutorials
      tutorialsList[tuto] = !tutorialsList[tuto]
      this.tutorials = tutorialsList
    },
    toggleViewedTutorials (val) {
      let tutorialsList = this.$store.state.Preferences.tutorials
      Object.keys(tutorialsList).forEach(el => {
        tutorialsList[el] = val
      })
      // Pour clearer la liste si un probleme
      // let tutorialsList = {
      //   home: false,
      //   devoirEdition: false,
      //   tableauEdition: false
      // }
      this.tutorials = tutorialsList
    }
  }
}
</script>
<template lang='pug'>
v-dialog(v-model='showTutorialDialog', max-width='800',content-class='tutorial-dialog', persistent, :fullscreen='$store.state.App.mobileTemplate')
  v-card.accent()
    //- pre {{$store.state.Preferences}}
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='showTutorialDialog = !showTutorialDialog', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "primary"')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-text.relative
      //- pre {{$store.state.Preferences.tutorials}}
      template(v-if='$route && $route.name && $route.name === "home"')
        home-tutorial(@startTour='launchTour', @updateTuto='updateViewedTutorials', @toggleAllTuto='toggleViewedTutorials')
      template(v-else-if='$route && $route.name && $route.name === "devoirEdition"')
        devoir-tutorial(@startTour='launchTour', @updateTuto='updateViewedTutorials', @toggleAllTuto='toggleViewedTutorials')
      template(v-else)
        fallback-tutorial
</template>
<style lang='sass'>
  @import 'src/styles/components/dialogs/_tutorialDialog'
  .small-tooltip
    max-width: 200px
    font-size: 12px
</style>
