<script>
export default {
  name: 'selectionEleves',
  components: {
  },
  data () {
    return {
      quantity: 1,
      open: [],
      lastOpen: [],
      allOpened: false,
      loading: false,
      finish: false,
      showError: false,
      searchEleves: null,
      selectedEleves: [],
      defaultItem: {
        id: 1,
        name: 'Groupe no. 1',
        children: [
          { id: 'hardcodded-2', name: 'Jean-Claude Hardcodded', parent: 'Groupe no. 1' },
          { id: 'hardcodded-3', name: 'Gustave Hardcodded', parent: 'Groupe no. 1' },
          { id: 'hardcodded-4', name: 'Emilie Hardcodded', parent: 'Groupe no. 1' }
        ]
      }
    }
  },
  computed: {
    relations () {
      return this.$store.state.User.relations.map(e => e.inferieur)
    },
    showElevesSelection: {
      set (val) { this.$store.commit('Dialogs/setElevesSelection', val) },
      get () { return this.$store.state.Dialogs.showElevesSelection }
    }
  },
  methods: {
    reset () {
      this.loading = false
      this.showError = false
      this.finish = false
      this.quantity = 1
    },
    pushToList () {
      this.$store.commit('Dialogs/setSelectedEleves', this.selectedEleves)
      this.showElevesSelection = false
      this.$store.commit('App/setShowSnackbarGlobal', true)
      this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: this.$t('alerte.succes'), description: this.$t('alerte.ajout-eleve') })
    },
    handleSearch (val) {
      if (val) {
        if (!this.allOpened) {
          this.allOpened = true
          this.$refs.treeEleves.updateAll(true)
        }
      } else {
        this.$refs.treeEleves.updateAll(false)
        this.allOpened = false
      }
    }
  }
}
</script>

<template lang='pug'>
v-dialog(v-model='showElevesSelection', max-width='500',content-class='custom-dialog', persistent, scrollable, :fullscreen="$store.state.App.windowSize.width < 700")
  v-dialog(v-model='loading', hide-overlay, persistent, width='400')
    v-card(color='primary', dark)
      v-card-text
        div.dialog-text.pa4.secondary--text
          div.b.f4.mb3.tc Validation en cours...
          v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)

  v-card.light(v-if='!finish')
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='showElevesSelection = !showElevesSelection', @click.native='reset', depressed, fab, x-large, color='light')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text Sélectionner des enfants
    v-card-text.relative
      v-text-field.mt3(v-model='searchEleves', @input='handleSearch', label='Trouver des enfants', rounded, solo, clearable, clear-icon='mdi-close-circle-outline', hide-details)
      div
        section
          v-card-text
            div.section-title.b.mb-2 Mes enfants
            v-list(color='primary')
              v-list-item( v-for='enfant in relations', index)
                v-checkbox(v-model='selectedEleves', :label="enfant.info.nom +' ' + enfant.info.prenom" :value="enfant")
    div.flex-grow-1
    v-btn.secondary--text(@click='pushToList()', color='primary', depressed, block, x-large, :disabled='!selectedEleves || selectedEleves.length < 1')
      span.f4 Sélectionner ces enfants

  v-card.light(v-else-if='finish')
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='showElevesSelection = !showElevesSelection', @click.native='reset', depressed, fab, x-large, color='light')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.primary.white--text {{quantity > 1 ?'Les comptes ont bien été créés.' : 'Le compte a bien été créé.'}}
    v-card-text.dark--text
      div.dialog-text
        div.f5.mb2.b Vous recevrez une confirmation par courriel avec toutes les informations nécessaires.
        div.i.f6 Vous devrez rafraîchir votre page pour voir les comptes enfants apparaître dans votre espace parent.
</template>
<style lang='sass' scoped>
.v-list
  > .v-list-item
    min-height: 50px
    &:nth-child(odd)
      background-color: var(--v-light-darken1)
    &:nth-child(even)
      background-color: var(--v-light-darken2)
</style>
